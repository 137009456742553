import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import { dateTemplate } from '@services/utils'
import UserCredentials from './UserCredentials'
import UserProfileCardTitle from './UserProfileCardTitle'

function UserProfileCard({
  direction = 'vertical',
  isLoading = false,
  onEdit,
  user,
}) {
  if (!user) {
    return null
  }

  const title = { body: (<UserProfileCardTitle user={user} />) }
  const {
    initials, email, upn, phone, createdAt, timezone, customerId,
  } = user

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const action = onEdit ? { label: 'Edit', command: onEdit } : null

  return (
    <AttributeCard title={title} direction={direction} action={action}>
      {
        initials && (
          <Attribute
            label="Initials:"
            value={valueTemplate(initials || 'Empty')}
          />
        )
      }
      {
        email && (
          <Attribute
            label="Email:"
            value={valueTemplate(email || 'Empty')}
          />
        )
      }
      {
        upn && (
          <Attribute
            label="UPN:"
            value={valueTemplate(upn || 'Empty')}
          />
        )
      }
      {
        phone && (
          <Attribute
            label="Phone:"
            value={valueTemplate(phone || 'Empty')}
          />
        )
      }
      {
        customerId && (
          <Attribute
            label="Custom ID:"
            value={valueTemplate(customerId || 'Empty')}
          />
        )
      }
      <Attribute
        label="Timezone:"
        value={valueTemplate(timezone || 'Empty')}
      />
      <Attribute
        label="Created At:"
        value={valueTemplate(dateTemplate(createdAt))}
      />
      <UserCredentials user={user} />
    </AttributeCard>
  )
}

export default UserProfileCard
