import React, {
  useState, useEffect, useRef, useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import FormWrapper from '@components/display/Form/FormWrapper'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import {
  find, first, get, isEmpty, last,
} from 'lodash'
import { useCurrentOrganization } from '@components/App'
import { generateMonthYearsDropdownOptions } from '@services/utils/moment'
import { useCreateReport } from './reportsHooks'
import { usePatients } from '../Patients/patientHooks'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import { REPORT_TYPES, reportTypeDateRange } from './config'

const optionToMoment = (option) => moment(`${option.year}-${option.month}-01`, 'YYYY-MM-DD')

function CreateReport({ useReportsHeader }) {
  const currentOrganization = useCurrentOrganization()
  const statusMessage = useRef(null)
  const { setHeader } = useReportsHeader()
  const navigate = useNavigate()

  const [reportType, setReportType] = useState('mar')
  const [selectedSites, setSelectedSites] = useState([])
  const [availablePatients, setAvailablePatients] = useState([])
  const [selectedPatients, setSelectedPatients] = useState([])

  const dateRange = useMemo(
    () => reportTypeDateRange(reportType),
    [reportType],
  )
  const monthYearsMenuOptions = useMemo(
    () => generateMonthYearsDropdownOptions(dateRange.rangeStart, dateRange.rangeEnd),
    [reportType],
  )
  const [selectedMonthYearStart, setSelectedMonthYearStart] = useState()
  const [selectedMonthYearEnd, setSelectedMonthYearEnd] = useState()

  useEffect(() => {
    const now = moment()
    const end = optionToMoment(last(monthYearsMenuOptions))
    const endDiff = now.diff(end)

    if (endDiff < 0) {
      setSelectedMonthYearEnd(first(monthYearsMenuOptions))
      setSelectedMonthYearStart(first(monthYearsMenuOptions))
    } else {
      setSelectedMonthYearEnd(last(monthYearsMenuOptions))
      setSelectedMonthYearStart(last(monthYearsMenuOptions))
    }
  }, [monthYearsMenuOptions])

  const {
    mutateAsync: createReport,
    isLoading: isCreateLoading,
  } = useCreateReport(statusMessage)

  const {
    data: { sites } = [],
    isLoading: sitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: get(currentOrganization, 'id') })

  const {
    data: patients,
    isLoading: patientsLoading,
  } = usePatients(get(currentOrganization, 'id'), statusMessage)

  const handleCreate = async () => {
    await createReport({
      reportType,
      organizationId: currentOrganization.id,
      siteIds: selectedSites,
      patientIds: selectedPatients,
      startMonth: selectedMonthYearStart.month,
      startYear: selectedMonthYearStart.year,
      endMonth: selectedMonthYearEnd.month,
      endYear: selectedMonthYearEnd.year,
    }, {
      onSuccess: () => {
        navigate('../')
      },
    })
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Reports
      </span>
    ),
    linkTo: '/admin/reports',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => (
    setHeader({ title, breadcrumbs, primaryAction: null })
  ), [])

  const setPatientOptions = (selectedSiteIds, selectedSiteObjects) => {
    // the available patients are the patients that are in the selected sites
    // if the site is empty, don't show it because there is a bug in the multiselect
    // when the first group is empty (site with no patients) the select all button
    // doesn't work
    const patientsBySite = selectedSiteObjects.map((site) => ({
      label: site.name,
      code: site.id,
      items: patients.filter((patient) => patient.site?.id === site.id),
    })).filter((site) => !isEmpty(site.items))
    setAvailablePatients(patientsBySite)

    // default is all patients selected
    const sitesToAdd = selectedSiteIds.filter((id) => !selectedSites.includes(id))
    setSelectedSites(selectedSiteIds)
    const newPatients = patients.filter((patient) => (
      sitesToAdd.includes(patient.site.id)
    ))

    // remove patients where are not in available patients
    const filteredPatients = selectedPatients.filter((patient) => (
      patientsBySite.map((p) => p.id).includes(patient.id)
    ))

    setSelectedPatients([...filteredPatients, ...newPatients.map((p) => p.id)])
  }

  const onSiteSelect = (e) => {
    const selectedSiteIds = e.value
    const selectedSiteObjects = selectedSiteIds.map((id) => find(sites, { id }))

    setPatientOptions(selectedSiteIds, selectedSiteObjects)
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Report" fullHeight>
          <div className="field">
            <label className="block" htmlFor="reportType">Report Type</label>
            <Dropdown
              value={reportType}
              options={REPORT_TYPES}
              onChange={(e) => setReportType(e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder="Select a type"
              className="w-12"
            />
          </div>
          <div className="field">
            <label className="block" htmlFor="sites">Sites</label>
            <MultiSelect
              value={selectedSites}
              options={sites}
              onChange={onSiteSelect}
              placeholder="Select Sites"
              className="w-12"
              optionLabel="name"
              optionValue="id"
              disabled={sitesLoading}
              filter
              filterBy="name"
              filterPlaceholder="Search by name"
            />
          </div>
          {reportType !== 'barcode_scans' && (
            <div className="field">
              <label className="block" htmlFor="patients">{currentOrganization.patientLabelPlural}</label>
              <MultiSelect
                value={selectedPatients}
                options={availablePatients}
                onChange={(e) => setSelectedPatients(e.value)}
                placeholder={`Select ${currentOrganization.patientLabelPlural}`}
                className="w-12"
                optionLabel="fullName"
                optionValue="id"
                disabled={patientsLoading || sitesLoading || !selectedSites.length}
                filter
                filterBy="name"
                filterPlaceholder="Search by name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                showSelectAll
              />
            </div>
          )}
          <div className="field">
            <label className="block" htmlFor="sites">Date</label>
            <span className="block text-sm text-gray-500 mb-2">Start</span>
            <Dropdown
              value={selectedMonthYearStart}
              options={monthYearsMenuOptions}
              onChange={(e) => {
                setSelectedMonthYearStart(e.value)
                if (optionToMoment(e.value).isAfter(optionToMoment(selectedMonthYearEnd))) {
                  setSelectedMonthYearEnd(e.value)
                }
              }}
              placeholder="Select a Month"
              className="w-12"
            />
            <span className="block text-sm text-gray-500 mb-2 mt-2">End</span>
            <Dropdown
              value={selectedMonthYearEnd}
              options={monthYearsMenuOptions}
              onChange={(e) => {
                setSelectedMonthYearEnd(e.value)
                if (optionToMoment(e.value).isBefore(optionToMoment(selectedMonthYearStart))) {
                  setSelectedMonthYearStart(e.value)
                }
              }}
              placeholder="Select a Month"
              className="w-12"
            />
          </div>
          <Button
            label="Create Report"
            className="p-button-sm mt-4"
            loading={isCreateLoading}
            onClick={handleCreate}
            disabled={reportType === 'barcode_scans' ? isEmpty(selectedSites) : isEmpty(selectedPatients)}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreateReport
