import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { TabPanel, TabView } from 'primereact/tabview'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { usePageOutlet as usePageLayoutContext } from '@components/display/PageLayout'
import MenuTab from '@components/display/MenuTab'
import OrdersPendingWarning from '@components/patients/PatientList/OrdersPendingWarning'
import MedsDueHeader from '@components/patients/PatientList/MedsDueHeader'
import { usePendingUsers } from '@hooks/pendingUsers'
import SitesSelect from '../Selects/SitesSelect/SitesSelect'
import ActivePatients from './ActivePatients'
import PendingUsers from '../PendingUsers/PendingUsers'
import DeactivatedPatients from './DeactivatedPatients'
import UnassignedPatients from './UnassignedPatients'
import { useMedPassPatients, useUnassignedPatients } from './patientHooks'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import './PatientsTable.scss'

const activePanelHeaderTemplate = (options) => (
  <MenuTab
    label="Active"
    active={options.selected}
    onClick={options.onClick}
  />
)

const pendingPanelHeaderTemplate = (options, pendingUsers) => (
  <MenuTab
    label="Pending"
    active={options.selected}
    onClick={options.onClick}
    badge={pendingUsers?.length > 0 ? pendingUsers.length : null}
    badgeClassName="p-badge-warning"
  />
)

const deactivatedPanelHeaderTemplate = (options) => (
  <MenuTab
    label="Deactivated"
    active={options.selected}
    onClick={options.onClick}
  />
)

const unassignedPanelHeaderTemplate = (options) => (
  <MenuTab
    label="Unassigned"
    active={options.selected}
    onClick={options.onClick}
  />
)

function Title({
  availableSites,
  organization,
  searchTerm,
  setSearchTerm,
  isLoading,
  sitesLoading,
  selectedSites,
  setSelectedSites,
}) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.patientLabelPlural}
      </span>
      <SearchField
        isLoading={isLoading}
        placeholder={`Search ${organization.patientLabelPlural} by name`}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <SitesSelect
        oneOrganization
        sites={availableSites}
        selectedSites={selectedSites}
        loading={sitesLoading}
        onChange={setSelectedSites}
        placeholder="Filter by site"
      />
    </div>
  )
}

function PatientsTable() {
  const statusMessage = useRef(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const isCurrentUserAdmin = currentUser.role === 'site_admin' || currentUser.role === 'superadmin'
  const { setHeader, maxDataViewHeight, rootPath } = usePageLayoutContext()
  const [footer, setFooter] = useState(null)
  const [selectedSites, setSelectedSites] = useState([])
  const canViewPendingUsers = isCurrentUserAdmin && organization.hasIntegration
  const canViewUnassignedPatients = currentUser.role === 'superadmin'

  const {
    data: pendingUsers,
    isFetching: isPendingUsersFetching,
  } = usePendingUsers(organization.id, canViewPendingUsers)

  const {
    data: patients,
    isFetching,
  } = useMedPassPatients(organization.id, statusMessage)

  const {
    data: unassignedPatients,
    isFetching: isUnassignedPatientsFetching,
  } = useUnassignedPatients(organization.id, canViewUnassignedPatients, statusMessage)

  const {
    data: { sites } = [],
    isLoading: sitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: organization.id })

  const primaryAction = isCurrentUserAdmin && (
    <Link to={`${rootPath}/create`}>
      <Button className="p-button-sm" label={`Create ${organization.patientLabelSingular}`} />
    </Link>
  )

  const title = {
    label: (
      <Title
        availableSites={sites}
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
        sitesLoading={sitesLoading}
        selectedSites={selectedSites}
        setSelectedSites={setSelectedSites}
      />
    ),
  }

  const footerTemplate = (patientsCount, arePatientsLoading) => (
    <span className="text-sm ml-1 my-0 py-0">
      {!arePatientsLoading ? `${organization.patientLabelPlural} Count: ${patientsCount}` : ''}
    </span>
  )

  useEffect(() => {
    setHeader({
      rootPath, title, tabs: [], breadcrumbs: [], primaryAction, notifications: [],
    })
  }, [searchTerm, searchLoading, rootPath, organization, sitesLoading, selectedSites])

  const numDueNow = patients.filter((patient) => patient.medsDueNow != null).length
  const ordersPending = patients.filter((patient) => patient.numReviewableOrders > 0).length

  return (
    <div>
      <Card className="h-full">
        { ordersPending > 0
          && <OrdersPendingWarning ordersPending={ordersPending} organization={organization} />}
        <TabView panelContainerClassName="py-2 px-0" activeIndex={1}>
          { numDueNow > 0 && (
            <TabPanel
              headerTemplate={MedsDueHeader({
                label: `${numDueNow === 1 ? organization.patientLabelSingular : organization.patientLabelPlural} ${numDueNow === 1 ? 'has' : 'have'} meds due`,
                count: numDueNow,
              })}
              headerClassName="custom-tabview-tab-meds-due"
            />
          )}
          <TabPanel header="Active" headerTemplate={activePanelHeaderTemplate} headerClassName="custom-tabview-tab-active">
            <ActivePatients
              patients={patients}
              isFetching={isFetching}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              maxDataViewHeight={maxDataViewHeight}
              rootPath={rootPath}
              setFooter={setFooter}
              selectedSites={selectedSites}
            />
            {footer}
          </TabPanel>
          { canViewPendingUsers && (
            <TabPanel header="Pending" headerTemplate={(options) => pendingPanelHeaderTemplate(options, pendingUsers)} headerClassName="custom-tabview-tab-pending">
              <PendingUsers
                organization={organization}
                pendingUsers={pendingUsers}
                isFetching={isPendingUsersFetching}
                searchTerm={searchTerm}
                setSearchLoading={setSearchLoading}
                maxDataViewHeight={maxDataViewHeight}
                setFooter={setFooter}
              />
              {footer}
            </TabPanel>
          ) }
          <TabPanel header="Deactivated" headerTemplate={deactivatedPanelHeaderTemplate} headerClassName="custom-tabview-tab-deactivated">
            <DeactivatedPatients
              statusMessage={statusMessage}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              maxDataViewHeight={maxDataViewHeight}
              rootPath={rootPath}
              footer={footerTemplate}
            />
          </TabPanel>
          {
            canViewUnassignedPatients && (
              <TabPanel header="Unassigned" headerTemplate={unassignedPanelHeaderTemplate} headerClassName="custom-tabview-tab-unassigned">
                <UnassignedPatients
                  statusMessage={statusMessage}
                  patients={unassignedPatients}
                  isFetching={isUnassignedPatientsFetching}
                  searchTerm={searchTerm}
                  setSearchLoading={setSearchLoading}
                  maxDataViewHeight={maxDataViewHeight}
                  rootPath={rootPath}
                  setFooter={setFooter}
                />
                {footer}
              </TabPanel>
            )
          }
        </TabView>
      </Card>
    </div>
  )
}

export default PatientsTable
