import React from 'react'

import style from './slotIdentifier.module.sass'

function SlotIdentifier({ drawerSlot }) {
  if (!drawerSlot) {
    return 'N/A'
  }
  const { xPos, yPos } = drawerSlot

  const visualSlot = (row, column) => {
    const isHighlighted = (xPos === (column + 1)) && yPos === (4 - row)
    return (
      <span key={column} className={isHighlighted ? style.highlightedSlot : style.visualSlot} />
    )
  }

  return (
    <div className="w-2rem" data-testid="slot-identifier" style={{ height: '52px' }}>
      {
          Array.from({ length: 4 }, (_, row) => (
            <div key={row} className={style.slotVisualRow}>
              {/* eslint-disable-next-line */}
              {Array.from({ length: 3 }, (_, column) => visualSlot(row, column))}
            </div>
          ))
        }
    </div>
  )
}

export default SlotIdentifier
