import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useDeactivatedCaregivers(organizationId) {
  return useQuery({
    queryKey: ['deactivatedCaregivers', organizationId],
    queryFn: () => {
      const params = { organizationId }

      return ApiClient.get('/caregivers/deactivated', { params })
    },
    enabled: !!organizationId,
    placeholderData: [],
    select: (data) => data?.caregivers || [],
  })
}

export default useDeactivatedCaregivers
