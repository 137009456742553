import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useSetUsername = (statusMessage, userRole) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['useSetUsername'],
    mutationFn: ({ userId, username }) => ApiClient.post(`/users/${userId}/set_username`, { username }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userRole)
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Username updated',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useSetUsername
