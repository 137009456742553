import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useLeaveOfAbsenceDeleteMutation = (statusMessage) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['deleteLeaveOfAbsence'],
    mutationFn: (leaveOfAbsenceId) => ApiClient.delete(`/leave_of_absences/${leaveOfAbsenceId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('leave_of_absences')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useLeaveOfAbsenceDeleteMutation
