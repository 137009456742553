import React, { useRef, useState, useEffect } from 'react'
import { get, map } from 'lodash'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import { Link } from 'react-router-dom'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { TabView, TabPanel } from 'primereact/tabview'
import MenuTab from '@components/display/MenuTab'
import { useCaregivers } from './caregiversHooks'
import PendingUsers from '../SSODirectoryUsers/PendingUsers'
import { useSSOPendingUsers } from '../SSODirectoryUsers/ssoPendingUsersHooks'
import ActiveCaregivers from './ActiveCaregivers'
import DeactivatedCaregivers from './DeactivatedCaregivers'

const activePanelHeaderTemplate = (options) => (
  <MenuTab
    label="Active"
    active={options.selected}
    onClick={options.onClick}
  />
)

const pendingOnboardPanelHeaderTemplate = (options, pendingCaregivers, canManageSsoForOrg) => (
  canManageSsoForOrg && (
    <MenuTab
      label="Pending SSO"
      active={options.selected}
      onClick={options.onClick}
      badge={pendingCaregivers?.length > 0 ? pendingCaregivers.length : null}
      badgeClassName="p-badge-warning"
      icon="pi pi-users"
    />
  )
)

const deactivatedPanelHeaderTemplate = (options) => (
  <MenuTab
    label="Deactivated"
    active={options.selected}
    onClick={options.onClick}
  />
)

function Title({
  organization, searchTerm, setSearchTerm, isLoading,
}) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.caregiverLabelPlural}
      </span>
      <SearchField
        className="w-18rem"
        isLoading={isLoading}
        placeholder={`Search ${organization.caregiverLabelPlural} by name`}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  )
}

function CaregiversTable({ useCaregiverHeader, canManageSsoForOrg }) {
  const statusMessage = useRef(null)
  const { setHeader, maxDataViewHeight } = useCaregiverHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const rootPath = '/admin/caregivers'

  const { data: caregivers, isLoading } = useCaregivers(organization.id, currentUser)
  const {
    data: pendingCaregivers,
    isLoading: isLoadingPendingCaregivers,
  } = useSSOPendingUsers({
    organizationId: organization.id,
    role: 'caregiver',
    enabled: canManageSsoForOrg,
  })

  const title = {
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const sitesOptions = Array.from(new Set(map(caregivers, (caregiver) => (get(caregiver, 'sites') || []).map((site) => site.name)).flat()))

  const primaryAction = (
    <Link to="/admin/caregivers/create">
      <Button className="p-button-sm" label={`Create ${organization.caregiverLabelSingular}`} />
    </Link>
  )

  const footer = (caregiversCount, areCaregiversLoading) => (
    <span className="text-sm ml-1 my-0 py-0">
      {!areCaregiversLoading ? `${organization.caregiverLabelPlural} Count: ${caregiversCount}` : ''}
    </span>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  return (
    <div>
      <Messages ref={statusMessage} />
      <Card className="h-full">
        <TabView panelContainerClassName="py-2 px-0">
          <TabPanel header="Active" headerTemplate={activePanelHeaderTemplate}>
            <ActiveCaregivers
              caregivers={caregivers}
              isLoading={isLoading}
              maxDataViewHeight={maxDataViewHeight}
              sitesOptions={sitesOptions}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              rootPath={rootPath}
            />
            {footer(caregivers.length, isLoading)}
          </TabPanel>
          <TabPanel header="Pending SSO Onboard" headerTemplate={(options) => pendingOnboardPanelHeaderTemplate(options, pendingCaregivers, canManageSsoForOrg)}>
            <PendingUsers
              pendingUsers={pendingCaregivers}
              existingUsers={caregivers}
              organization={organization}
              isLoading={isLoadingPendingCaregivers}
              statusMessage={statusMessage}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
            />
            {footer(pendingCaregivers.length, isLoading)}
          </TabPanel>
          <TabPanel header="Deactivated" headerTemplate={deactivatedPanelHeaderTemplate} headerClassName="custom-tabview-tab-deactivated">
            <DeactivatedCaregivers
              statusMessage={statusMessage}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              maxDataViewHeight={maxDataViewHeight}
              rootPath={rootPath}
              footer={footer}
            />
          </TabPanel>
        </TabView>
      </Card>
    </div>
  )
}

export default CaregiversTable
