import React from 'react'
import moment from 'moment-timezone'
import { isEmpty, map } from 'lodash'
import EmptyCell from '../../EmptyCell'
import StatusTag from '../statusTag'

function StatusIndicator({
  consumptions,
  dateStr,
  timeStr,
  marTimeRow,
  timezone,
  isAdmin,
  onEmptyCellClick,
  onConsumptionSelect,
}) {
  const cellTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm A').tz(timezone, true)
  const currentTime = moment()

  if (isEmpty(consumptions)) {
    if (cellTime.isAfter(moment())) return null
    if (!isAdmin) return null
    return (
      <EmptyCell onClick={() => onEmptyCellClick(marTimeRow, cellTime)} />
    )
  }

  return (
    <div className="flex align-items-center justify-content-center">
      {map(consumptions, (consumption) => {
        const willBeAllowedAt = moment(consumption.willBeAllowedAt)
        if (willBeAllowedAt.isBefore(currentTime)) {
          return (
            <div key={consumption.id} className="flex align-items-center justify-content-center">
              <StatusTag
                status={consumption.status}
                initials={consumption?.confirmedBy?.initials}
                onClick={(e) => onConsumptionSelect(consumption, marTimeRow, cellTime, e)}
              />
            </div>
          )
        }

        return isAdmin ? (
          <EmptyCell
            key={consumption.id}
            onClick={() => onEmptyCellClick(marTimeRow, cellTime)}
          />
        ) : null
      })}
    </div>
  )
}

export default StatusIndicator
