import React from 'react'

function mapRange(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

// After this was made a developer pointed out that there is an open source component we can
// leverage https://github.com/kevinsqi/react-circular-progressbar It would take some customization
// so if you find yourself making changes to this indicator, consider switching to the open source
// component.

function CircularMedPassProgress({
  size = 32, strokeWidth = 3, borderProgress = 0, fillProgress = 0, count = 0,
}) {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (borderProgress / 100) * circumference

  // When showing fill progress, 10% looks like nothing and 90% looks fully complete
  // This helpers allows us to normalize the range 0-100 to 10-90 compressing the range
  // so it is more visible
  const fillProgressNormalized = mapRange(fillProgress, 0, 100, 10, 90)
  const fillColor = 'var(--blue-200)'

  let strokeColor = 'var(--blue-400)'
  if (borderProgress === 0) {
    strokeColor = 'lightgrey'
  } else if (borderProgress < 25) {
    strokeColor = 'var(--red-400)'
  } else if (borderProgress < 50) {
    strokeColor = 'var(--orange-400)'
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <clipPath id="clipCircle">
          <circle
            r={radius - strokeWidth}
            cx={size / 2}
            cy={size / 2}
          />
        </clipPath>
      </defs>
      {fillProgress > 0 && (
      <rect
        x="0"
        y="0"
        width={(fillProgressNormalized / 100) * size}
        height={size}
        fill={fillColor}
        clipPath="url(#clipCircle)"
        style={{ transition: 'width 0.5s ease', opacity: 0.4 }}
      />
      )}
      <circle
        stroke="lightgrey"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        stroke={strokeColor}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transition: 'stroke-dashoffset 0.5s ease' }}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fontSize="1em"
        fontWeight="bold"
        fill={strokeColor}
      >
        {count}
      </text>
    </svg>
  )
}

export default CircularMedPassProgress
