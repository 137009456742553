import React, { useState } from 'react'
import { sumBy, filter, some } from 'lodash'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Knob } from 'primereact/knob'
import { useCurrentCapabilities } from '@components/App'
import { FulfillmentStatus } from '../FullfillmentStatuses'
import { useUpdateDosePARlevel } from '../../../features/InventoryManagement/inventoryHooks'

function ParLevelInfo({
  parLevel,
  setParLevel,
  isEditing,
  setIsEditing,
  isLoading,
  handleSave,
  handleCancel,
  canUpdateParLevel,
}) {
  const handleEdit = () => {
    setIsEditing(true)
  }

  const isParLevelValid = () => parLevel >= 0

  if (isEditing) {
    return (
      <div className="flex flex-row align-items-center">
        <InputText
          className="p-inputtext-sm block par-level-input"
          style={{ maxWidth: '40%' }}
          placeholder="PAR level"
          value={parLevel}
          onChange={(e) => setParLevel(e.target.value)}
        />
        <Button
          onClick={handleSave}
          className="p-button-sm p-button-text p-button-success"
          icon="pi pi-check"
          loading={isLoading}
          disabled={!isParLevelValid()}
        />
        <Button
          onClick={handleCancel}
          className="p-button-sm p-button-text p-button-secondary"
          icon="pi pi-times"
        />
      </div>
    )
  }
  return (
    <div className="flex flex-row align-items-center">
      <span>
        PAR level:
        {' '}
        {parLevel}
      </span>
      {canUpdateParLevel && (
        <Button
          onClick={handleEdit}
          className="p-button-sm p-button-text p-button-secondary p-0 ml-1"
          icon="pi pi-pencil"
        />
      )}
    </div>
  )
}

function InventoryCell({
  parLevel, doseFills, id: doseId, prn, fillsLeft,
}) {
  const currentCapabilities = useCurrentCapabilities()
  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updateDosePARlevel, isLoading: isUpdatingParLevel } = useUpdateDosePARlevel()
  const [newParLevel, setParLevel] = useState(parLevel)

  const loadedByAgencyDoseFills = filter(doseFills, {
    status: FulfillmentStatus.loadedByAgency,
  })
  const aggregateDosesInFill = sumBy(loadedByAgencyDoseFills, 'dosesInFill')
  const aggregateFillsLeft = fillsLeft || aggregateDosesInFill

  const color = () => {
    const parLevelToCalculate = isEditing ? parLevel : newParLevel
    if (aggregateFillsLeft <= parLevelToCalculate) {
      return '#ffd0ce' // red
    } if (
      ((aggregateFillsLeft - parLevelToCalculate) / parLevelToCalculate) * 100
      <= 20
    ) {
      return '#faedc4' // yellow
    }
    return '#caf1d8' // green
  }

  const handleSave = async () => {
    await updateDosePARlevel({
      doseId,
      parLevel: newParLevel,
    })
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setParLevel(parLevel)
  }

  const inventoryTemplate = () => {
    const hasInventory = aggregateDosesInFill > 0
    if (hasInventory) {
      return (
        <span>
          Quantity:
          {' '}
          {aggregateFillsLeft}
          {' '}
          /
          {' '}
          {aggregateDosesInFill}
        </span>
      )
    }

    const hasPendingDoseFills = some(doseFills, { status: FulfillmentStatus.pending })
    const text = hasPendingDoseFills ? 'Please load doses' : 'No inventory'

    return <span className="font-italic">{text}</span>
  }

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <Knob
        size={40}
        min={0}
        max={aggregateDosesInFill}
        value={aggregateFillsLeft}
        valueColor={color()}
        readOnly
      />
      <div className="flex flex-column gap-2 justify-content-center">
        {prn && (
          <ParLevelInfo
            parLevel={newParLevel}
            setParLevel={setParLevel}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            doseId={doseId}
            isLoading={isUpdatingParLevel}
            handleSave={handleSave}
            handleCancel={handleCancel}
            canUpdateParLevel={currentCapabilities.inventory.canUpdateParLevel}
          />
        )}
        {inventoryTemplate()}
      </div>
    </div>
  )
}

export default InventoryCell
