import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'

function DropdownEditor({
  isLoading,
  option,
  optionLabel,
  options,
  setOption,
  showClear,
}) {
  const [dropdownOption, setDropdownOption] = useState(option)

  const handleConfirmation = () => {
    setOption(dropdownOption)
  }

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <Dropdown
        value={dropdownOption}
        onChange={({ target: { value } }) => setDropdownOption(value)}
        options={options}
        optionLabel={optionLabel}
        placeholder="Select an option"
        showClear={showClear}
      />
      <Button
        onClick={handleConfirmation}
        loading={isLoading}
        icon="pi pi-check"
      />
    </div>
  )
}

export default DropdownEditor
