import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SuperAdminCard from '@components/display/SuperAdminCard'
import WorkosMessagesTable from '@components/sso/WorkosMessagesTable'
import UserProfileCard from '@components/user/UserProfileCard'
import { useCurrentOrganization } from '@components/App'
import ConsumptionsChart from '../display/Consumptions/ConsumptionsChart'
import ConsumptionsTable from '../display/Consumptions/ConsumptionsTable'
import { useCaregiverConsumptionHistory } from '../../features/Caregivers/caregiversHooks'
import './caregivers.scss'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'

function CaregiverDashboard({
  caregiverId, caregiver, caregiverLoading, handleSetHeader, maxDataViewHeight,
}) {
  const navigate = useNavigate()
  const organization = useCurrentOrganization()
  const {
    data: consumptions,
    isLoading: consumptionsLoading,
  } = useCaregiverConsumptionHistory(caregiverId)

  const [chartHeight, setChartHeight] = useState(0)

  const canViewWorkosMessages = organization.ssoEnabled && caregiver?.isDirectoryManaged

  const primaryAction = (
    <NotificationTestButton userId={caregiverId} />
  )

  useEffect(() => {
    if (caregiver && !chartHeight) {
      const profileCard = document.getElementById('caregiver-dashboard-profile')
      const profileCardHeight = profileCard.offsetHeight
      setChartHeight(profileCardHeight - 94)
    }

    handleSetHeader({ primaryAction })
  }, [caregiver])

  const onProfileEdit = () => {
    navigate(`/admin/caregivers/${caregiver.id}/update`)
  }

  return (
    <div className="flex flex-row flex-wrap">
      <div id="caregiver-dashboard-profile" className={!organization.ccmEnabled ? 'md:col-12 lg:col-5' : 'col-12'}>
        <UserProfileCard
          isLoading={caregiverLoading}
          onEdit={onProfileEdit}
          user={caregiver}
        />
      </div>
      {
        !organization.ccmEnabled && (
          <>
            <div className="md:col-12 lg:col-7">
              <ConsumptionsChart
                title={`${organization.patientLabelPlural}' Medication Adherence`}
                consumptions={consumptions}
                isLoading={consumptionsLoading}
                height={chartHeight}
              />
            </div>
            <div className="col-12">
              <ConsumptionsTable
                title={`${organization.patientLabelPlural}' Consumptions`}
                consumptions={consumptions}
                isLoading={consumptionsLoading}
                maxDataViewHeight={maxDataViewHeight}
                userId={caregiverId}
                userRole="caregiver"
                patientLabel={organization.patientLabelSingular}
                showPatientName
              />
            </div>
          </>
        )
      }
      {
        canViewWorkosMessages && (
          <div className="col-12" data-testid="caregiver-workos-messages-card">
            <SuperAdminCard title="WorkOS Messages">
              <WorkosMessagesTable
                id={caregiverId}
                model="User"
              />
            </SuperAdminCard>
          </div>
        )
      }
    </div>
  )
}

export default CaregiverDashboard
