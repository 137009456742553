import moment from 'moment-timezone'
/**
 * @typedef {moment.Moment} Moment
 */

export const momentFormats = {
  date: 'YYYY-MM-DD',
  dateWithSlashes: 'MM/DD/YYYY',
  basicDate: 'YYYYMMDD',
  dateYear: 'MMM Do, YYYY',
  monthShortDay: 'MMM Do',
  onlyDay: 'D',
  dateYearTime: 'MMM Do, YYYY hh:mm A',
  time_12: 'hh:mm A',
  time_12_trim: 'h:mm A',
  time_24: 'HH:mm',
  datetime: 'YYYY-MM-DD HH:mm:ss ZZ',
  datetime_12: 'YYYY-MM-DD hh:mm A',
  datetime_24: 'YYYY-MM-DD HH:mm',
  iso8601: 'YYYY-MM-DD HH:mm:ss',
  iso8601_utc: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  iso8601_timezone: 'YYYY-MM-DDTHH:mm:ssZ',
  monthDay: 'MMM Do, YYYY',
}

/**
 * Parse iso8601 string to moment object e.g. '2020-01-01 12:00:00'
 * By default it will use the timezone of the browser
 *
 * @param {string} time
 * @returns {Moment}
 * @example
 * momentFromIso8601Str('2020-01-01 12:00:00')
*/
export function momentFromIso8601Str(time) {
  return moment(time, momentFormats.iso8601)
}

/**
 * Convert moment object to iso8601 string without timezone e.g. '2020-01-01 12:00:00'
 * @param {Moment} time
 * @returns {string}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentToIso8601Str(time) // '2020-01-01 12:00:00'
*/
export function momentToIso8601Str(time) {
  return time.format(momentFormats.iso8601)
}

/**
 * Convert moment object to iso8601 string with UTC timezone e.g. '2020-01-01T12:00:00Z'
 * Without converting the time, this means the time will be the same but the timezone will be UTC
 * @param {Moment} time
 * @returns {string}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentToIso8601StrUtc(time) '2020-01-01T12:00:00Z'
*/
export function momentToIso8601StrUtc(time) {
  return time.clone().utc().format(momentFormats.iso8601_utc)
}

/**
 * Convert moment object to local time without changing the time
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentTzWithoutChangingTime(time, 'America/New_York') // moment -> '2020-01-01 12:00:00 -0500'
 * momentTzWithoutChangingTime(time, 'America/Los_Angeles') // moment -> '2020-01-01 12:00:00 -0800'
*/
export const momentTzWithoutChangingTime = (momentTime, timezone) => {
  if (!momentTime) return null
  return momentTime
    .clone().tz(timezone, true)
}

/**
 * Convert moment object to local time without changing the time, if timezone is provided
 * first we convert the moment to the timezone changing the time and then we convert it to
 * local time without changing the time
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
*/
export const momentToLocalWithoutChangingTime = (momentTime, timezone = null) => {
  if (!momentTime) return null
  if (!timezone) {
    return momentTime.clone().local(true)
  }
  return momentTime.clone().tz(timezone).local(true)
}

/**
 * Convert moment object to local time without changing the time, if timezone is provided
 * We are going to set the timezone without changing the time, if is not provided we are going to
 * use the browser timezone
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
*/
export const momentFromMomentDateAndTime = (momentDate, momentTime, timezone = null) => {
  const dateStr = momentDate.format('YYYY-MM-DD')
  const timeStr = momentTime.format('HH:mm:ss')
  if (!timezone) {
    return momentFromIso8601Str(`${dateStr} ${timeStr}`)
  }
  return momentFromIso8601Str(`${dateStr} ${timeStr}`).tz(timezone, true)
}

/**
 * This function takes an integer representing the total number of minutes into a day
 * and returns a moment object for the current day with the hours and minutes set.
 * @param {number} totalMinutes
 */
export const getMomentFromMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const mins = totalMinutes % 60

  return moment().startOf('day').hour(hours).minute(mins)
}

/**
 * This function takes an integer representing the total number of minutes into a day
 * and returns a formatted time string in the optional format.
 * @param minutes
 * @param {string} format
 * @returns {*|string}
 */
export const getDisplayTimeFromMinutes = (minutes, format = momentFormats.time_12_trim) => {
  if (moment.isMoment(minutes)) {
    return minutes.format(format)
  }

  if (typeof minutes !== 'number') {
    return 'Unknown time'
  }

  return getMomentFromMinutes(minutes).format(format)
}

/**
 * This function returns a moment in the middle of the moment range.
 *
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @returns {Moment}
 */
export const getMiddleMoment = (start, end) => start.clone().add(end.diff(start) / 2, 'ms')

/**
 * This function converts a moment object in a certain timezone to a date but preserves the
 * time of the moment object.
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Date}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentToDateWithTime(time, 'America/New_York') // Date -> '2020-01-01 12:00:00 -0500'
 */
export const momentToDatePreservingTime = (momentTime, timezone) => {
  if (!momentTime) return null
  return new Date(momentTime.tz(timezone).format('YYYY-MM-DDTHH:mm:ss'))
}

/**
 * Returns a moment object from a string, if the string is null or undefined, it returns null.
 * @param {string} time
 * @returns {Moment}
 */
export const momentOrNull = (time) => {
  if (!time) return null
  return moment(time)
}

/**
 * This function formats a moment object from a string if it's not null or undefined.
 * Returns an empty string if the time is null or undefined.
 * @param {string} time
 * @param {string} format
 * @returns {string}
 */
export const formatMomentOrNull = (time, format, timezone = null) => {
  if (!time) return ''
  if (timezone) {
    return moment(time).tz(timezone).format(format)
  }
  return moment(time).format(format)
}

/**
 * Generate an array of json objects with the month and year
 * @param {Moment} start - start date of the range, the day is not important, default is 1 year ago
 * @param {Moment} end - end date of the range, the day is not important, default is today
 */
export const generateMonthYearsDropdownOptions = (start = moment().subtract(1, 'year'), end = moment()) => {
  const months = []
  let current = start.clone()
  while (current.isSameOrBefore(end)) {
    months.push({
      label: current.format('MMMM YYYY'),
      // month is 0 indexed, but we want to use 1 indexed months
      month: current.month() + 1,
      year: current.year(),
    })

    // get the next month to add it to the options only if it's before the end date
    current = current.clone().add(1, 'month')
  }
  return months
}
