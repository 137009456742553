import React from 'react'
import { classNames } from 'primereact/utils'
import { useRefreshFromWorkos } from '@hooks/sso'

/**
 * Displays a refresh icon that refreshes the user from WorkOS.
 *
 * @param {object} statusMessageRef - The ref for the status message component.
 * @param {object} user - The user to refresh.
 */
function WorkOsRefreshIcon({ statusMessageRef, user }) {
  const {
    mutate: refreshFromWorkOs,
    loading: loadingRefresh,
  } = useRefreshFromWorkos({ statusMessage: statusMessageRef, model: 'User', id: user?.id })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      refreshFromWorkOs()
    }
  }

  return (
    <i
      className={classNames('pi', {
        'pi-refresh': !loadingRefresh,
        'pi-spin': loadingRefresh,
      })}
      onClick={refreshFromWorkOs}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    />
  )
}

export default WorkOsRefreshIcon
