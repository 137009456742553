import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { classNames } from 'primereact/utils'

const initialSettingMenu = [
  {
    name: 'details',
    label: 'Details',
  },
  {
    name: 'admin-settings',
    label: 'Admin Settings',
  },
  {
    name: 'event-times',
    label: 'Event Times',
  },
]

function MainMenu({ organizationId }) {
  const [selectedTab, setSelectedTab] = useState('details')
  const params = useParams()

  const orgId = organizationId || params.id

  return (
    <div className="flex flex-row w-max gap-5 border-bottom-1 border-gray-300">
      {initialSettingMenu.map(({ name, label }) => (
        <div
          key={name}
          className={classNames('h-full pb-3', {
            'text-primary border-primary border-bottom-1 border-primary': selectedTab === `${name}`,
          })}
        >
          <Link to={`/admin/organizations/${orgId}/edit/${name}`} onClick={() => setSelectedTab(`${name}`)}>
            <span className={classNames('text-base font-semibold text-gray-600', {
              'text-primary': selectedTab === `${name}`,

            })}
            >
              {label}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default MainMenu
