import React, { useState } from 'react'
import { useCurrentOrganization } from '@components/App'
import DeactivatedUsers from '@components/users/DeactivatedUsers'
import { useDeactivatedCaregivers, useReactivateCaregiver } from '@hooks/caregivers'
import ReactivateCaregiverDialog from './ReactivateCaregiverDialog'

function DeactivatedCaregivers({
  statusMessage,
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  rootPath,
  footer,
}) {
  const organization = useCurrentOrganization()
  const [reactivatedCaregiverId, setReactivatedCaregiverId] = useState()
  const [reactivateDialogVisible, setReactivateDialogVisible] = useState(false)

  const {
    data: deactivatedCaregivers,
    isLoading: isLoadingDeactivatedCaregivers,
  } = useDeactivatedCaregivers(organization.id)

  const {
    mutateAsync: reactivateCaregiver,
    isLoading: isReactivateCaregiverLoading,
  } = useReactivateCaregiver(statusMessage, organization.caregiverLabelSingular)

  const reactivateDialog = (handleReactivate) => (
    <ReactivateCaregiverDialog
      statusMessage={statusMessage}
      organization={organization}
      caregiverId={reactivatedCaregiverId}
      visible={reactivateDialogVisible}
      onHide={() => setReactivateDialogVisible(false)}
      onConfirm={handleReactivate}
      isLoading={isReactivateCaregiverLoading}
    />
  )

  return (
    <DeactivatedUsers
      statusMessage={statusMessage}
      users={deactivatedCaregivers}
      isLoading={isLoadingDeactivatedCaregivers}
      searchTerm={searchTerm}
      setSearchLoading={setSearchLoading}
      maxDataViewHeight={maxDataViewHeight}
      reactivateUser={reactivateCaregiver}
      isReactivateUserLoading={isReactivateCaregiverLoading}
      reactivatedUserId={reactivatedCaregiverId}
      setReactivatedUserId={setReactivatedCaregiverId}
      rootPath={rootPath}
      showReactivateDialog
      reactivateDialog={reactivateDialog}
      setReactivateDialogVisible={setReactivateDialogVisible}
      footer={footer}
    />
  )
}

export default DeactivatedCaregivers
