import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { confirmDialog } from 'primereact/confirmdialog'
import {
  DoseAttributeRow,
  TextAreaEditor,
  EditButton,
  DropdownEditor,
  DateEditor,
} from '@components/DoseAttributeRow'
import ScheduleEditor from '@components/clientDoses/Schedule/ScheduleEditor/ScheduleEditor'
import { AddTimeEditor, PrnEditor } from '@components/pharmacyOrders/Editors'
import { getTaskActionFriendlyName } from '@components/tasks/utils'
import { momentFormats, momentToLocalWithoutChangingTime, momentTzWithoutChangingTime } from '@services/utils/moment'

// Schedule components don't use a consistent schedule data structure, so we need to format
// the schedule this way to make use of the ScheduleEditor component. It will be much easier to
// refactor this logic after adopting TypeScript.
const formatSchedule = (schedule) => ({
  flexibility: schedule.flexibilityAttributes,
  rrule: schedule.rrule,
  time_hh_mm: schedule.time_hh_mm,
  time: schedule.time_hh_mm.split(':').reduce((acc, time) => (60 * acc) + parseInt(time, 10), 0),
  timeHours: parseInt(schedule.time_hh_mm.split(':')[0], 10),
  timeMinutes: parseInt(schedule.time_hh_mm.split(':')[1], 10),
})

export function ReviewTaskTable({
  patient,
  treatment,
  task,
  setTask,
}) {
  const [editAttribute, setEditAttribute] = useState(null)

  useEffect(() => {
    const firstTask = treatment?.tasks?.[0] || {}

    setTask({
      id: firstTask.id,
      title: firstTask.title || '',
      description: treatment?.pharmacyInstructions || '',
      taskAction: firstTask?.taskAction || 'binary',
      taskType: firstTask?.taskType || treatment?.treatmentType || 'routine',
      showOnTar: firstTask?.showOnTar || true,
      startAt: treatment?.startAt,
      endAt: treatment?.endAt,
      schedules: firstTask?.schedules || [],
    })
  }, [treatment])

  if (!treatment) {
    return null
  }

  const handleCreateSchedule = ({ schedule }) => {
    setTask({
      ...task,
      schedules: [...task.schedules, formatSchedule(schedule)],
    })
  }

  const handleUpdateSchedule = ({ schedule }) => {
    const unchangedSchedules = task.schedules
      .filter((s) => s.rrule !== schedule.rrule || s.time !== schedule.time)
    setTask({
      ...task,
      schedules: [...unchangedSchedules, formatSchedule(schedule)],
    })
  }

  const handleDeleteSchedule = ({ schedule }) => {
    const schedulesToKeep = task.schedules
      .filter((s) => s.rrule !== schedule.rrule || s.time !== schedule.time)
    setTask({ ...task, schedules: [...schedulesToKeep] })
  }

  const schedules = task.schedules?.map((schedule) => ({
    ...schedule,
    // TODO: This is a hack to avoid refactoring a bunch of schedule components. We should remove
    //       this and refactor frontend schedule components after the introduction of TypeScript.
    momentTime: moment().set({
      hour: schedule.timeHours,
      minute: schedule.timeMinutes,
      second: 0,
      millisecond: 0,
    }).valueOf(),
  }))

  const handleUpdateAttribute = (attribute, value) => {
    const updatedTask = { ...task }
    updatedTask[attribute] = value

    if (attribute === 'showOnTar' && typeof value === 'object') {
      updatedTask.showOnTar = value?.value
    }

    if (attribute === 'taskAction' && typeof value === 'object') {
      updatedTask.taskAction = value?.value
    }

    setTask(updatedTask)
    setEditAttribute(null)
  }

  const { timezone } = patient

  const taskAttributes = [
    {
      label: 'Title',
      currentValue: task.title,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'title' ? setEditAttribute(null) : setEditAttribute('title'))}
          isEditing={editAttribute === 'title'}
          disabled={editAttribute && editAttribute !== 'title'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={task.title}
          setText={(value) => handleUpdateAttribute('title', value)}
        />
      ),
      isEditing: editAttribute === 'title',
    },
    {
      label: 'Description',
      currentValue: task.description,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'description' ? setEditAttribute(null) : setEditAttribute('description'))}
          isEditing={editAttribute === 'description'}
          disabled={editAttribute && editAttribute !== 'description'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={task.description}
          setText={(value) => handleUpdateAttribute('description', value)}
        />
      ),
      isEditing: editAttribute === 'description',
    },
    {
      label: 'Action',
      currentValue: getTaskActionFriendlyName(task.taskAction),
      action: (
        <EditButton
          onClick={editAttribute === 'taskAction' ? () => setEditAttribute(null) : () => setEditAttribute('taskAction')}
          isEditing={editAttribute === 'taskAction'}
          disabled={editAttribute && editAttribute !== 'taskAction'}
        />
      ),
      editor: (
        <DropdownEditor
          option={task.taskAction}
          optionLabel="label"
          options={[
            { label: getTaskActionFriendlyName('binary'), value: 'binary' },
            { label: getTaskActionFriendlyName('text'), value: 'text' },
            { label: getTaskActionFriendlyName('vital'), value: 'vital' },
          ]}
          setOption={(value) => handleUpdateAttribute('taskAction', value)}
        />
      ),
      isEditing: editAttribute === 'taskAction',
    },
    {
      label: 'Show on TAR',
      currentValue: task.showOnTar ? 'Yes' : 'No',
      action: (
        <EditButton
          onClick={editAttribute === 'showOnTar' ? () => setEditAttribute(null) : () => setEditAttribute('showOnTar')}
          isEditing={editAttribute === 'showOnTar'}
          disabled={editAttribute && editAttribute !== 'showOnTar'}
        />
      ),
      editor: (
        <DropdownEditor
          option={task.showOnTar}
          optionLabel="label"
          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
          setOption={(value) => handleUpdateAttribute('showOnTar', value)}
        />
      ),
      isEditing: editAttribute === 'showOnTar',
    },
    {
      label: 'Start Date',
      currentValue: task.startAt && timezone
        ? moment(task.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
          disabled={editAttribute && editAttribute !== 'startAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={task.startAt
            ? momentToLocalWithoutChangingTime(moment(task.startAt), timezone) : null}
          setMomentDate={(value) => {
            const startToSet = momentTzWithoutChangingTime(value.startOf('day'), timezone)
            return handleUpdateAttribute('startAt', startToSet.toISOString())
          }}
          max={task.endAt ? moment(task.endAt) : null}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: task.endAt && timezone
        ? moment(task.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={task.endAt
            ? momentToLocalWithoutChangingTime(moment(task.endAt), timezone) : null}
          min={task.startAt ? moment(task.startAt) : null}
          setMomentDate={(value) => {
            if (!value) {
              return handleUpdateAttribute('endAt', null)
            }
            const newEnd = momentTzWithoutChangingTime(value.endOf('day'), timezone)
            if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
              confirmDialog({
                message: 'The end date is before today, are you sure you want to continue?',
                header: 'Warning',
                accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
                icon: 'pi pi-exclamation-triangle',
              })
            } else {
              return handleUpdateAttribute('endAt', newEnd.toISOString())
            }
          }}
          showButtonBar
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'PRN',
      currentValue: task.taskType === 'prn' ? 'Yes' : 'No',
      action: (
        <PrnEditor prn={task.taskType === 'prn'} setPrn={(value) => setTask({ ...task, taskType: value ? 'prn' : 'routine' })} />
      ),
    },
    {
      label: 'Schedule',
      display: task.taskType === 'routine',
      currentValue: (
        <ScheduleEditor
          schedules={schedules}
          handleUpdateSchedule={handleUpdateSchedule}
          handleDeleteSchedule={handleDeleteSchedule}
        />
      ),
      action: (
        <AddTimeEditor
          handleCreateSchedule={handleCreateSchedule}
          schedulableType="Task"
        />
      ),
    },
  ]

  return (
    <div className="col-12">
      <ul className="list-none p-0 m-0 dose-order-fields">
        {taskAttributes
          .filter(({ display = true }) => display)
          .map(({
            label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
          }, index) => (
            <DoseAttributeRow
              key={label}
              label={label}
              valueChangeHandler={valueChangeHandler}
              currentValue={currentValue}
              newValue={newValue}
              action={action}
              editor={editor}
              isEditing={isEditing}
              backgroundHighlight={index % 2 === 0}
            />
          ))}
      </ul>
    </div>
  )
}

export default ReviewTaskTable
