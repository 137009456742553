import ApiClient from '@services/ApiClient'
import { useQuery } from '@tanstack/react-query'

const useUserActivationsQuery = (userId) => useQuery({
  queryKey: ['userActivations', userId],
  queryFn: () => ApiClient.get('/user_activations', { params: { user_id: userId } }),
  enabled: !!userId,
})

export default useUserActivationsQuery
