import React, { useEffect, useRef } from 'react'
import { get, find } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import DashboardStats from '../../components/medManagement/DashboardStats'
import HighRiskDoses from './HighRiskDoses'
import OnTimeDoses from './OnTimeDoses'
import HighRiskImpacts from './HighRiskImpacts'
import ControlBar from '../../components/medManagement/ControlBar'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import {
  useHighRiskConsumptions,
  useHighRiskImpacts,
  useOnTimeConsumptions,
  usePermissionRequests,
} from './medManagementHooks'
import PermissionRequests from './PermissionRequests'

const VIEWS = {
  highRisk: 'high_risk',
  onTime: 'on_time',
  negativeImpacts: 'negative_impacts',
  permissionRequests: 'permission_requests',
}

const timeframeOptions = [
  {
    label: 'Past 24 Hours',
    value: '24h',
  },
  // {
  //   label: 'Past Week',
  //   value: '7d',
  // },
  // {
  //   label: 'Past Month',
  //   value: '1m',
  // },
]

function PageTitle({ organization, children }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-sitemap mr-2" />
        {`${organization.name} Dashboard`}
      </span>
      {children}
    </div>
  )
}

function MedManagementDashboard({ useMedManagementHeader }) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()
  const { setHeader, maxDataViewHeight } = useMedManagementHeader()

  const [queryParams, setQueryParams] = useSearchParams({
    view: VIEWS.highRisk,
    timeframe: 'Past 24 Hours',
  })

  const timePeriod = get(
    find(timeframeOptions, ({ label }) => label === queryParams.get('timeframe')),
    'value',
  )

  const siteIds = queryParams.getAll('siteIds')

  const highRiskConsumptions = useHighRiskConsumptions({ timePeriod, siteIds, statusMessage })
  const onTimeConsumptions = useOnTimeConsumptions({ timePeriod, siteIds, statusMessage })
  const highRiskImpacts = useHighRiskImpacts({
    organizationId: organization.id,
    timePeriod,
    siteIds,
    statusMessage,
  })
  const permissionRequests = usePermissionRequests({ timePeriod, siteIds, statusMessage })
  const { data: { sites } = [], isLoading: sitesLoading } = useSitesQuery({ statusMessage })

  const paramsToObject = (params) => Object.fromEntries(params.entries())

  const setSelectedSites = (newSiteIds) => setQueryParams((previous) => ({
    ...paramsToObject(previous), siteIds: newSiteIds,
  }))

  const setView = (view) => setQueryParams((previous) => ({
    ...paramsToObject(previous), view,
  }))

  const setTimeframe = (timeframe) => setQueryParams((previous) => ({
    ...paramsToObject(previous), timeframe,
  }))

  const title = {
    label: (
      <PageTitle organization={organization}>
        <ControlBar
          isSitesLoading={sitesLoading}
          availableSites={sites}
          selectedSites={queryParams.getAll('siteIds')}
          setSelectedSites={setSelectedSites}
          timeframeOptions={timeframeOptions}
          timeframe={queryParams.get('timeframe')}
          setTimeframe={setTimeframe}
        />
      </PageTitle>
    ),
  }

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction: null,
    })
  }, [sitesLoading, queryParams])

  return (
    <div className="flex flex-column gap-1">
      <Messages ref={statusMessage} />
      <DashboardStats
        organization={organization}
        highRiskConsumptions={highRiskConsumptions}
        onTimeConsumptions={onTimeConsumptions}
        highRiskImpacts={highRiskImpacts}
        permissionRequests={permissionRequests}
        activeView={queryParams.get('view')}
        setView={setView}
        views={VIEWS}
      />
      {queryParams.get('view') === VIEWS.highRisk && (
        <HighRiskDoses
          timeframe={queryParams.get('timeframe')}
          maxDataViewHeight={maxDataViewHeight}
          organization={organization}
          highRiskConsumptions={highRiskConsumptions}
          onTimeConsumptions={onTimeConsumptions}
        />
      )}
      {queryParams.get('view') === VIEWS.onTime && (
        <OnTimeDoses
          timeframe={queryParams.get('timeframe')}
          maxDataViewHeight={maxDataViewHeight}
          organization={organization}
          onTimeConsumptions={onTimeConsumptions}
          highRiskConsumptions={highRiskConsumptions}
        />
      )}
      {queryParams.get('view') === VIEWS.negativeImpacts && (
        <HighRiskImpacts
          maxDataViewHeight={maxDataViewHeight}
          organization={organization}
          highRiskImpacts={highRiskImpacts}
        />
      )}
      {queryParams.get('view') === VIEWS.permissionRequests && (
      <PermissionRequests
        maxDataViewHeight={maxDataViewHeight}
        organization={organization}
        permissionRequests={permissionRequests}
      />
      )}
    </div>
  )
}

export default MedManagementDashboard
