import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import InputTextWithLabel from '../InputTextWithLabel'

function MedicalConditionForm({
  medicalCondition, saveMedicalCondition, updateMedicalCondition, isLoading, hide,
}) {
  const [description, setDescription] = useState(get(medicalCondition, 'description', ''))
  const [icdCode, setIcdCode] = useState(get(medicalCondition, 'icdCode', ''))
  const [icdVersion, setIcdVersion] = useState('10')

  const handleSave = async () => {
    await saveMedicalCondition({ description, icdCode, icdVersion })
  }

  const handleUpdate = async () => {
    await updateMedicalCondition({
      id: medicalCondition.id, description, icdCode, icdVersion,
    })
  }

  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <InputTextWithLabel
        label="ICD Code"
        value={icdCode}
        onChange={(e) => setIcdCode(e.target.value)}
      />
      <InputTextWithLabel
        label="ICD Version"
        value={icdVersion}
        disabled
        onChange={(e) => setIcdVersion(e.target.value)}
      />
      <div className="flex flex-row mt-3 justify-content-end">
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-sm p-button-outlined"
          onClick={async () => {
            if (medicalCondition) {
              await handleUpdate()
            } else {
              await handleSave()
            }
            hide()
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

function MedicalConditionList({
  medicalConditions,
  statusMessageRef,
  isLoading,
  doseId,
  patientId,
  createMutation,
  updateMutation,
  deleteMutation,
}) {
  const [isCreationDialogVisible, setIsCreationDialogVisible] = useState(false)
  const [selectedMedicalCondition, setSelectedMedicalCondition] = useState()
  const [searchTerm, setSearchTerm] = useState()
  const [filteredConditions, setFilteredConditions] = useState([])

  const saveMedicalCondition = async (medicalCondition) => {
    await createMutation(
      {
        ...medicalCondition,
        patientId,
        doseId,
      },
      {
        onSuccess: () => {
          setSelectedMedicalCondition(null)
          setIsCreationDialogVisible(false)
        },
      },
    )
  }

  const deleteMedicalCondition = async (medicalCondition) => {
    await deleteMutation(medicalCondition.id)
  }

  const updateMedicalCondition = async (medicalCondition) => {
    await updateMutation({
      ...medicalCondition,
      patientId,
      doseId,
    }, {
      onSuccess: () => {
        setSelectedMedicalCondition(null)
        setIsCreationDialogVisible(false)
      },
    })
  }

  const actionsTemplate = (rowData) => {
    // We want to allow edit medical conditions that come from the pharmacy
    // only if they are associated with a patient, not a dose
    // in case of a dose we don't want to allow editing/deleting
    if (rowData.source === 'pharmacy' && rowData.doseId) {
      return null
    }
    return (
      <div className="flex flex-row">
        <Button
          className="p-button-sm p-button-text py-1 px-2"
          icon="pi pi-pencil"
          tooltip="Edit"
          tooltipOptions={{ position: 'top' }}
          onClick={() => {
            setSelectedMedicalCondition(rowData)
            setIsCreationDialogVisible(true)
          }}
        />
        <Button
          className="p-button-sm p-button-text py-1 px-2"
          icon="pi pi-trash"
          tooltip="Delete"
          tooltipOptions={{ position: 'top' }}
          onClick={() => deleteMedicalCondition(rowData)}
        />
      </div>
    )
  }

  useEffect(() => {
    if (searchTerm) {
      setFilteredConditions(exactSearch(medicalConditions, ['description', 'icdCode'], searchTerm))
    } else {
      setFilteredConditions(medicalConditions)
    }
  }, [medicalConditions, searchTerm])

  return (
    <div className="col-12">
      <Toast ref={statusMessageRef} />
      <Dialog
        header="Add Medical Condition"
        visible={isCreationDialogVisible}
        style={{ width: '50vw' }}
        onHide={() => setIsCreationDialogVisible(false)}
      >
        <MedicalConditionForm
          saveMedicalCondition={saveMedicalCondition}
          updateMedicalCondition={updateMedicalCondition}
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          medicalCondition={selectedMedicalCondition}
          hide={() => setIsCreationDialogVisible(false)}
        />
      </Dialog>
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center px-1">
          <SearchField
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder="Search by description or code"
          />
          <Button
            label="Add Condition"
            className="p-button-sm"
            onClick={() => setIsCreationDialogVisible(true)}
          />
        </div>
        <DataTable
          value={filteredConditions}
          loading={isLoading}
          emptyMessage="No medical conditions"
          dataKey="id"
          sortField="description"
          sortOrder={1}
        >
          <Column field="description" header="Description" sortable />
          <Column field="icdCode" header="ICD Code" sortable />
          <Column field="icdVersion" header="ICD Version" />
          <Column
            header="Actions"
            body={actionsTemplate}
          />
        </DataTable>
      </div>
    </div>
  )
}

export default MedicalConditionList
