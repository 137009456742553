import React, { useRef, useState } from 'react'
import { useCurrentOrganization } from '@components/App'
import DeactivatedUsers from '@components/users/DeactivatedUsers'
import { useDeactivatedAdmins, useReactivateAdmin } from './adminsHooks'

function DeactivatedAdmins({
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  rootPath,
  footer,
}) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()

  const [reactivatedAdminId, setReactivatedAdminId] = useState()

  const { data: admins, isLoading } = useDeactivatedAdmins(organization.id, statusMessage)
  const {
    mutateAsync: reactivateAdmin,
    isLoading: isReactivatedAdminLoading,
  } = useReactivateAdmin(statusMessage, organization.adminLabelSingular)

  return (
    <DeactivatedUsers
      statusMessage={statusMessage}
      users={admins}
      isLoading={isLoading}
      searchTerm={searchTerm}
      setSearchLoading={setSearchLoading}
      maxDataViewHeight={maxDataViewHeight}
      reactivateUser={reactivateAdmin}
      isReactivateUserLoading={isReactivatedAdminLoading}
      reactivatedUserId={reactivatedAdminId}
      setReactivatedUserId={setReactivatedAdminId}
      rootPath={rootPath}
      footer={footer}
    />
  )
}

export default DeactivatedAdmins
