import React, { useRef, useState } from 'react'
import { get, sumBy } from 'lodash'
import AttributeCard from '@components/display/AttributeCard'
import { administrationRoutes } from '@components/clientDoses/config'
import { Checkbox } from 'primereact/checkbox'
import { Toast } from 'primereact/toast'
import pluralize from 'pluralize'
import {
  DoseAttributeRow,
  DateEditor,
  TextAreaEditor,
  EditButton,
  DropdownEditor,
  AdministrationRouteEditor,
} from '@components/DoseAttributeRow'
import { csaScheduleText } from '@components/medicines/csaScheduleLabel'
import { ConfirmDialog } from 'primereact/confirmdialog'
import moment from 'moment-timezone'
import { momentFormats, momentToLocalWithoutChangingTime } from '@services/utils/moment'
import './client-dose.scss'
import { useCurrentOrganization } from '@components/App'
import {
  useDisabledNarcoticCounts, useDisableNarcoticCount, useEnableNarcoticCount, useUpsertDose,
} from '../clientDosesHooks'

function DosingTable({ dose }) {
  const organization = useCurrentOrganization()
  const statusMessages = useRef(null)

  const {
    mutateAsync: updateDose,
    isLoading,
  } = useUpsertDose({
    patientId: dose.patientId,
    statusMessage: statusMessages,
    isUpdate: true,
  })

  const [editAttribute, setEditAttribute] = useState(null)

  const handleUpdateAttribute = (attribute, value) => {
    const payload = { id: dose.id, medicine: dose.medicine }

    if (attribute === 'csaSchedule') {
      payload.medicine.csaSchedule = value
    } else {
      payload[attribute] = value
    }

    updateDose(payload, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  const {
    data: disabledNarcoticCountsData,
    isFetched: isFetchedDisabledNarcoticCounts,
  } = useDisabledNarcoticCounts(organization.id)

  const disabledNarcoticCounts = get(disabledNarcoticCountsData, 'disabledNarcoticCounts')
  let narcoticCountEnabled

  if (isFetchedDisabledNarcoticCounts && narcoticCountEnabled == null) {
    narcoticCountEnabled = !disabledNarcoticCounts.map(
      (setting) => setting.rxcui,
    ).includes(dose.medicine.rcui)
  }

  const {
    mutateAsync: enableNarcoticCount,
  } = useEnableNarcoticCount(organization.id, statusMessages)

  const {
    mutateAsync: disableNarcoticCount,
  } = useDisableNarcoticCount(organization.id, statusMessages)

  const handleNarcoticCount = ({ checked }) => {
    narcoticCountEnabled = checked

    if (checked) {
      const medicineSetting = disabledNarcoticCounts.find(
        (setting) => setting.rxcui === dose.medicine.rcui,
      )
      enableNarcoticCount(medicineSetting.id)
    } else {
      disableNarcoticCount(dose.medicine.rcui)
    }
  }

  const {
    medicine,
  } = dose

  const fillsLeft = sumBy(get(dose, 'doseFills'), 'fillsLeft')

  const requireInjectionSite = get(dose, 'requireInjectionSite', false)
  const route = get(dose, 'administrationRoute') || ''
  const injectable = get(dose, 'injectable', false)

  const doseAttributes = [
    {
      label: 'Controlled Substance',
      currentValue: (
        <div className="flex">
          <span className="align-self-center mr-4">{csaScheduleText(get(medicine, 'csaSchedule'))}</span>
          { isFetchedDisabledNarcoticCounts && (
            <div>
              <Checkbox className="mr-2" checked={narcoticCountEnabled} onChange={handleNarcoticCount} />
              <span className="align-self-center text-sm font-normal">Narcotic Count</span>
            </div>
          )}
        </div>
      ),
      display: Boolean(get(medicine, 'csaSchedule')),
      action: <EditButton
        onClick={editAttribute === 'csaSchedule' ? () => setEditAttribute(null) : () => setEditAttribute('csaSchedule')}
        isEditing={editAttribute === 'csaSchedule'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'csaSchedule'}
      />,
      editor: <DropdownEditor
        option={get(medicine, 'csaSchedule')}
        options={[
          { label: 'Schedule I', value: 1 },
          { label: 'Schedule II', value: 2 },
          { label: 'Schedule III', value: 3 },
          { label: 'Schedule IV', value: 4 },
          { label: 'Schedule V', value: 5 },
        ]}
        setOption={(value) => handleUpdateAttribute('csaSchedule', value)}
        isLoading={isLoading}
      />,
      isEditing: editAttribute === 'csaSchedule',
    },
    {
      label: 'Name',
      currentValue: get(medicine, 'name'),
    },
    {
      label: 'Route',
      currentValue: get(administrationRoutes[route], 'name'),
      action: <EditButton
        onClick={editAttribute === 'administrationRoute' ? () => setEditAttribute(null) : () => setEditAttribute('administrationRoute')}
        isEditing={editAttribute === 'administrationRoute'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'administrationRoute'}
      />,
      editor: <AdministrationRouteEditor
        administrationRoute={route}
        setAdministrationRoute={(value) => handleUpdateAttribute('administrationRoute', value)}
        isLoading={isLoading}
      />,
      isEditing: editAttribute === 'administrationRoute',
    },
    ...(injectable ? [{
      label: 'Injection Site Tracking Required',
      currentValue: requireInjectionSite ? 'Yes' : 'No',
      action: <EditButton
        onClick={editAttribute === 'requireInjectionSite' ? () => setEditAttribute(null) : () => setEditAttribute('requireInjectionSite')}
        isEditing={editAttribute === 'requireInjectionSite'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'requireInjectionSite'}
      />,
      editor: <DropdownEditor
        option={requireInjectionSite}
        optionLabel="label"
        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
        setOption={(value) => handleUpdateAttribute('requireInjectionSite', value)}
        isLoading={isLoading}
      />,
      isEditing: editAttribute === 'requireInjectionSite',
    }] : []),
    {
      label: 'Strength',
      currentValue: get(medicine, 'strength'),
    },
    {
      label: 'Form',
      currentValue: get(medicine, 'form'),
    },
    {
      label: 'Order Text',
      currentValue: get(dose, 'orderText'),
    },
    {
      label: 'Purpose',
      action: <EditButton
        onClick={editAttribute === 'purpose' ? () => setEditAttribute(null) : () => setEditAttribute('purpose')}
        isEditing={editAttribute === 'purpose'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'purpose'}
      />,
      currentValue: get(dose, 'purpose'),
      editor: <TextAreaEditor
        text={get(dose, 'purpose')}
        setText={(value) => handleUpdateAttribute('purpose', value ? { text: value } : null)}
        isLoading={isLoading}
      />,
      isEditing: editAttribute === 'purpose',
    },
    {
      label: 'Packaging',
      currentValue: get(dose, 'packaging'),
    },
    {
      label: 'Expiration Date',
      currentValue: dose?.expiration
        ? momentToLocalWithoutChangingTime(moment(dose.expiration)).format(momentFormats.monthDay)
        : null,
      editor: <DateEditor
        momentDate={dose?.expiration
          ? moment(dose.expiration) : null}
        setMomentDate={(value) => {
          if (!value) {
            return handleUpdateAttribute('expiration', null)
          }
          return handleUpdateAttribute('expiration', value.format(momentFormats.date))
        }}
      />,
      isEditing: editAttribute === 'expirationDate',
      action: <EditButton
        onClick={() => (editAttribute === 'expirationDate' ? setEditAttribute(null) : setEditAttribute('expirationDate'))}
        isEditing={editAttribute === 'expirationDate'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'expirationDate'}
      />,
    },
    {
      label: 'Quantity Remaining',
      currentValue: `${fillsLeft} ${pluralize(get(medicine, 'form') || '', fillsLeft)}`,
    },
    {
      label: 'Notes',
      currentValue: get(dose, 'note'),
      action: <EditButton
        onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
        isEditing={editAttribute === 'note'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'note'}
      />,
      isEditing: editAttribute === 'note',
      editor: <TextAreaEditor
        text={get(dose, 'note')}
        setText={(value) => handleUpdateAttribute('notes', value)}
        isLoading={isLoading}
      />,
    },
  ]

  return (
    <div className="col-12 dosing-table">
      <Toast ref={statusMessages} />
      <ConfirmDialog />
      <AttributeCard title={{ label: 'Dosing' }}>
        <ul className="list-none p-0 m-0 dose-order-fields">
          {doseAttributes
            .filter(({ display = true }) => display)
            .map(({
              label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
            }, index) => (
              <DoseAttributeRow
                key={label}
                label={label}
                valueChangeHandler={valueChangeHandler}
                currentValue={currentValue}
                newValue={newValue}
                action={action}
                editor={editor}
                isEditing={isEditing}
                backgroundHighlight={index % 2 === 0}
              />
            ))}
        </ul>
      </AttributeCard>
    </div>
  )
}

export default DosingTable
