import React, { useRef, useState } from 'react'
import moment from 'moment-timezone'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import {
  DateEditor, DoseAttributeRow, DropdownEditor, EditButton, TextAreaEditor,
} from '@components/DoseAttributeRow'
import { useUpdateTreatment } from '@hooks/treatments'
import { momentFormats, momentToLocalWithoutChangingTime, momentTzWithoutChangingTime } from '@services/utils/moment'
import { TreatmentActionMenu } from '@components/treatments/TreatmentActionMenu'

function TreatmentTable({
  treatment,
  setTreatment = null,
  timezone = 'America/New_York',
  canEdit = false,
  className = 'col-12',
  compositePharmacyOrder = null,
}) {
  const statusMessage = useRef(null)
  const [editAttribute, setEditAttribute] = useState(null)

  const {
    mutateAsync: updateTreatment,
    isLoading,
  } = useUpdateTreatment({ statusMessage })

  if (!treatment) {
    return null
  }

  const handleUpdateAttribute = (attribute, value) => {
    // Update local state when setTreatment is provided (e.g. needs review UX)
    if (setTreatment) {
      setTreatment({ ...treatment, [attribute]: value })
      setEditAttribute(null)
      return
    }

    // Since setTreatment was not provided, make an API call to update the treatment on each edit
    const payload = { id: treatment.id }
    payload[attribute] = value

    if (attribute === 'showInMedpass' && typeof value === 'object') {
      payload.showInMedpass = value?.value
    }

    updateTreatment(payload, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  const cardTitle = compositePharmacyOrder ? 'Review Treatment' : 'Treatment'
  const showActionMenu = !compositePharmacyOrder && canEdit
  const showPharmacyFields = treatment.treatmentSource === 'pharmacy'
  const showReminderInMedpassRow = !compositePharmacyOrder

  const treatmentAttributes = [
    {
      label: 'Instructions',
      currentValue: treatment.pharmacyInstructions,
      newValue: compositePharmacyOrder?.pharmacyInstructions,
      valueChangeHandler: () => (
        setTreatment({
          ...treatment,
          pharmacyInstructions: compositePharmacyOrder?.pharmacyInstructions,
        })
      ),
    },
    {
      label: 'Source',
      currentValue: `${treatment.pharmacy?.name} | ${treatment.orderNumber}`,
      display: showPharmacyFields,
    },
    {
      label: 'Start Date',
      currentValue: treatment.startAt && timezone
        ? moment(treatment.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      newValue: compositePharmacyOrder?.startAt && timezone
        ? moment(compositePharmacyOrder.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      valueChangeHandler: () => (
        setTreatment({
          ...treatment,
          startAt: compositePharmacyOrder?.startAt,
        })
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'startAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={treatment.startAt
            ? momentToLocalWithoutChangingTime(moment(treatment.startAt), timezone) : null}
          setMomentDate={(value) => {
            const startToSet = momentTzWithoutChangingTime(value.startOf('day'), timezone)
            return handleUpdateAttribute('startAt', startToSet.toISOString())
          }}
          max={treatment.endAt ? moment(treatment.endAt) : null}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: treatment.endAt && timezone
        ? moment(treatment.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      newValue: compositePharmacyOrder?.endAt && timezone
        ? moment(compositePharmacyOrder.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      valueChangeHandler: () => (
        setTreatment({
          ...treatment,
          endAt: compositePharmacyOrder?.endAt,
        })
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={treatment.endAt
            ? momentToLocalWithoutChangingTime(moment(treatment.endAt), timezone) : null}
          min={treatment.startAt ? moment(treatment.startAt) : null}
          setMomentDate={(value) => {
            if (!value) {
              return handleUpdateAttribute('endAt', null)
            }
            const newEnd = momentTzWithoutChangingTime(value.endOf('day'), timezone)
            if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
              confirmDialog({
                message: 'The end date is before today, are you sure you want to continue?',
                header: 'Warning',
                accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
                icon: 'pi pi-exclamation-triangle',
              })
            } else {
              return handleUpdateAttribute('endAt', newEnd.toISOString())
            }
          }}
          isLoading={isLoading}
          showButtonBar
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'Administration Times',
      currentValue: treatment.rawAdministrationTimes,
      newValue: compositePharmacyOrder?.rawAdministrationTimes,
      valueChangeHandler: () => (
        setTreatment({
          ...treatment,
          rawAdministrationTimes: compositePharmacyOrder?.rawAdministrationTimes,
        })
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'rawAdministrationTimes' ? setEditAttribute(null) : setEditAttribute('rawAdministrationTimes'))}
          isEditing={editAttribute === 'rawAdministrationTimes'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'rawAdministrationTimes'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={treatment.rawAdministrationTimes}
          setText={(value) => handleUpdateAttribute('rawAdministrationTimes', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'rawAdministrationTimes',
    },
    {
      label: 'Discontinued Date',
      display: treatment.discontinuedAt,
      currentValue: treatment.discontinuedAt && timezone
        ? moment(treatment.discontinuedAt).tz(timezone).format(momentFormats.dateYear) : null,
    },
    {
      label: 'Deleted Date',
      display: treatment.deletedAt,
      currentValue: treatment.deletedAt && timezone
        ? moment(treatment.deletedAt).tz(timezone).format(momentFormats.dateYear) : null,
    },
    {
      label: 'Reminder in Medpass',
      currentValue: treatment.showInMedpass ? 'Yes' : 'No',
      display: showReminderInMedpassRow,
      action: (
        <EditButton
          onClick={editAttribute === 'showInMedpass' ? () => setEditAttribute(null) : () => setEditAttribute('showInMedpass')}
          isEditing={editAttribute === 'showInMedpass'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'showInMedpass'}
        />
      ),
      editor: (
        <DropdownEditor
          option={treatment.showInMedpass}
          optionLabel="label"
          optionValue="value"
          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
          setOption={(value) => handleUpdateAttribute('showInMedpass', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'showInMedpass',
    },
    {
      label: 'Note',
      currentValue: treatment.note,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'note'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={treatment.note}
          setText={(value) => handleUpdateAttribute('note', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'note',
    },
  ]

  const helpText = showPharmacyFields
    ? 'The treatment data sent by your pharmacy.'
    : 'The treatment data entered by your care team.'

  const actionMenuTemplate = () => (
    <TreatmentActionMenu
      statusMessage={statusMessage}
      treatment={treatment}
    />
  )

  const tags = [{ value: treatment.categoryFriendlyName, rounded: false, severity: 'info' }]

  const actionProps = showActionMenu
    ? { ActionComponent: actionMenuTemplate }
    : {}

  return (
    <div className={className}>
      <ConfirmDialog />
      <Toast ref={statusMessage} />
      <AttributeCard
        tags={tags}
        title={{ helpText, label: cardTitle }}
        {...actionProps}
      >
        <ul className="list-none p-0 m-0 dose-order-fields">
          {treatmentAttributes
            .filter(({ display = true }) => display)
            .map(({
              label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
            }, index) => (
              <DoseAttributeRow
                key={label}
                label={label}
                valueChangeHandler={valueChangeHandler}
                currentValue={currentValue}
                currentValueLabel="Treatment Value"
                newValue={newValue}
                action={action}
                editor={editor}
                isEditing={isEditing}
                backgroundHighlight={index % 2 === 0}
              />
            ))}
        </ul>
      </AttributeCard>
    </div>
  )
}

export default TreatmentTable
