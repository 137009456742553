import React, { useRef, useState, useEffect } from 'react'
import { groupBy, map } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import { Tag } from 'primereact/tag'
import { Link } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import moment from 'moment'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import { useReportsQuery } from './reportsHooks'
import { REPORT_TYPES } from './config'

function Title({ searchTerm, setSearchTerm, isLoading }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Reports
      </span>
      <SearchField
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search reports by name"
        isLoading={isLoading}
      />
    </div>
  )
}

function ReportsTable({ useReportsHeader }) {
  const statusMessage = useRef(null)
  const { setHeader, maxDataViewHeight } = useReportsHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredReports, setFilteredReports] = useState([])

  const { data: reports, isLoading } = useReportsQuery(statusMessage)

  const organization = useCurrentOrganization()

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredReports(exactSearch(reports, ['patients.firstName', 'patients.lastName'], searchTerm))
    } else {
      setFilteredReports(reports)
    }
    setSearchLoading(false)
  }, [reports, searchTerm])

  const rootPath = '/admin/reports'

  const title = {
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const primaryAction = (
    <Link to="/admin/reports/create">
      <Button className="p-button-sm" label="Create Report" />
    </Link>
  )

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `Report Count: ${reports.length}` : ''}
    </span>
  )

  const sitesTemplate = (rowData) => (
    <div className="flex flex-column gap-2">
      <div className="flex flex-column gap-1">
        {map(rowData.sites, (site) => (
          <span>{site.name}</span>
        ))}
      </div>
    </div>
  )

  const patientsTemplate = (rowData) => {
    const bySite = groupBy(rowData.patients, 'site.name')

    return (
      <div className="flex flex-column gap-2">
        {map(bySite, (patientsBySite, siteName) => (
          <div className="flex flex-column gap-1">
            <span className="font-bold">{siteName}</span>
            {map(patientsBySite, (patient) => (
              <span>{`${patient.firstName} ${patient.lastName}`}</span>
            ))}
          </div>
        ))}
      </div>
    )
  }

  const dateTemplate = (rowData) => {
    // format to date like "January 2021 - February 2021"
    const {
      startMonth, startYear, endMonth, endYear,
    } = rowData
    const startDate = moment(`${startMonth} ${startYear}`, 'MM YYYY')
    const endDate = moment(`${endMonth} ${endYear}`, 'MM YYYY')
    return `${startDate.format('MMMM YYYY')} - ${endDate.format('MMMM YYYY')}`
  }

  const downloadTemplate = (rowData) => {
    const { status } = rowData
    if (status === 'ready') {
      return (
        <Button
          className="p-button-sm"
          label="Download"
          onClick={() => {
            const presignedUrl = rowData.presignedUrl.url
            window.open(presignedUrl, '_blank')
          }}
        />
      )
    }

    return (
      <Tag severity={status === 'failed' ? 'danger' : 'info'} value={status.toUpperCase()} />
    )
  }

  const typeTemplate = (rowData) => {
    const { reportType } = rowData
    const { label } = REPORT_TYPES.find((type) => type.value === reportType)
    return label
  }

  const createdTemplate = (rowData) => {
    const { createdAt } = rowData
    return moment(createdAt).format('lll')
  }

  useEffect(() => {
    setHeader({
      rootPath, title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <Card className="h-full" footer={footTemplate}>
        <div className="flex flex-column gap-3 text-base text-900">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredReports}
            sortField="lastName"
            sortOrder={1}
            className="mt-1"
            rowClassName="fadein reports-row"
            scrollable
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column header="Date" body={dateTemplate} />
            <Column header="Sites" body={sitesTemplate} />
            <Column header={organization.patientLabelPlural} body={patientsTemplate} />
            <Column header="Report Type" body={typeTemplate} />
            <Column header="Created At" body={createdTemplate} />
            <Column header="Download" body={downloadTemplate} />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default ReportsTable
