import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import OrgDetailsSettings from '../OrgSettings/Details/OrgDetailsSettings'
import AdminSettings from '../OrgSettings'
import OrganizationEventTimes from './OrganizationEventTimes'
import { useSettingsQuery } from '../OrgSettings/settingsHooks'
import MainMenu from '../OrgSettings/MainMenu'

function UpdateOrganization({
  statusMessage, timezones,
}) {
  const organization = useCurrentOrganization()
  const query = useSettingsQuery(organization.id, statusMessage)

  return (
    <div className="flex flex-column w-full gap-2">
      <MainMenu organizationId={organization.id} />
      <Routes>
        <Route path="/*" element={<Navigate to="details" replace />} />
        <Route
          index
          path="details"
          element={(
            <OrgDetailsSettings
              query={query}
              organizationId={organization.id}
              organization={organization}
              timezones={timezones}
              statusMessage={statusMessage}
            />
        )}
        />
        <Route
          path="admin-settings"
          element={(
            <AdminSettings
              query={query}
              organizationId={organization.id}
              organization={organization}
              statusMessage={statusMessage}
            />
        )}
        />
        <Route
          path="event-times"
          element={<OrganizationEventTimes />}
        />
      </Routes>
    </div>
  )
}

export default UpdateOrganization
