import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { get, isEmpty, map } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'
import SiteFilterDropdown from '@components/sites/SiteFilterDropdown'
import { exactSearch } from '@services/utils/search'
import { useAdmins } from './adminsHooks'

function ActiveAdmins({
  searchTerm, setSearchLoading, maxDataViewHeight, footer,
}) {
  const navigate = useNavigate()
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()

  const [siteFilter, setSiteFilter] = useState('')
  const [filteredAdmins, setFilteredAdmins] = useState([])

  const canViewSites = !organization.deviceBasedAccess

  const { data: admins, isLoading } = useAdmins(organization.id, statusMessage)

  useEffect(() => {
    let siteFilteredAdmins = admins
    if (siteFilter) {
      siteFilteredAdmins = admins.filter((admin) => (get(admin, 'sites') || [])
        .map((site) => site.name)
        .includes(siteFilter))
    }

    setSearchLoading(true)
    if (searchTerm) {
      setFilteredAdmins(exactSearch(siteFilteredAdmins, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredAdmins(siteFilteredAdmins)
    }
    setSearchLoading(false)
  }, [admins, searchTerm, siteFilter])

  const sitesOptions = Array.from(new Set(
    map(admins, (admin) => (get(admin, 'sites') || []).map((site) => site.name)).flat(),
  ))

  const handleSiteChange = (e) => {
    setSiteFilter(e.value)
    document.querySelector('.p-column-filter-menu-button-open').click()
  }

  const formatDirectorySync = ({ isDirectoryManaged }) => (
    isDirectoryManaged
      ? <UserDirectoryServiceIcon service="active-directory" tooltipPosition="left" />
      : null
  )

  return (
    <div className="flex flex-column text-base text-900 w-full">
      <Messages ref={statusMessage} />
      <DataTable
        dataKey="id"
        loading={isLoading}
        value={filteredAdmins}
        selectionMode="single"
        onSelectionChange={(e) => navigate(`/admin/admins/${e.value.id}`)}
        sortField="lastName"
        sortOrder={1}
        rowClassName="fadein admin-row"
        scrollable
        scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
      >
        <Column header="First Name" field="firstName" />
        <Column header="Last Name" field="lastName" sortable />
        {
          canViewSites && (
            <Column
              header="Sites"
              field="sites"
              body={(rowData) => {
                const { sites } = rowData
                if (isEmpty(sites)) return 'No sites assigned'
                if (sites.length === 1) {
                  return sites[0].name
                }
                return `Assigned to ${sites.length} sites`
              }}
              filter
              filterElement={(
                <SiteFilterDropdown
                  sites={sitesOptions}
                  value={siteFilter}
                  handleSiteChange={handleSiteChange}
                />
              )}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterMenuClassName="admins-site-filter"
            />
          )
        }
        <Column field="directorySync" body={formatDirectorySync} />
      </DataTable>
      {footer(filteredAdmins.length, isLoading)}
    </div>
  )
}

export default ActiveAdmins
