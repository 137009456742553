// eslint-disable-next-line import/prefer-default-export
import moment from 'moment'

export const REPORT_TYPES = [
  {
    label: 'MAR',
    value: 'mar',
  },
  {
    label: 'PRN MAR',
    value: 'prn_mar',
  },
  {
    label: 'Medication Errors',
    value: 'medication_errors',
  },
  {
    label: 'Clinical Notes',
    value: 'clinical_notes',
  },
  {
    label: 'Physician Orders',
    value: 'physician_orders',
  },
  {
    label: 'Bowel Movements',
    value: 'bowel_movements',
  },
  {
    label: 'Narcotic Counts',
    value: 'narcotic_counts',
  },
  {
    label: 'Barcode Scans',
    value: 'barcode_scans',
  },
]

export const reportTypeDateRange = (reportType) => {
  switch (reportType) {
    case 'mar':
    case 'prn_mar':
    case 'medication_errors':
    case 'clinical_notes':
    case 'bowel_movements':
    case 'barcode_scans':
    case 'narcotic_counts':
      return {
        rangeStart: moment().subtract(2, 'years'),
        rangeEnd: moment(),
      }
    case 'physician_orders':
      return {
        rangeStart: moment(),
        rangeEnd: moment().add(3, 'months'),
      }
    default:
      return {
        rangeStart: moment().subtract(2, 'years'),
        rangeEnd: moment(),
      }
  }
}
