import { lazy } from 'react'
import RoleLabels from '@components/orgSettings/RoleLabels'

/*  Lazy loading these components to improve the initial load time of the application.
    This ensures that these components are only loaded when they are needed, reducing
    the initial bundle size.
*/
const Inventory = lazy(() => import('@components/orgSettings/Inventory'))
const MobileApp = lazy(() => import('@components/orgSettings/MobileApp'))
const PrnSettings = lazy(() => import('@components/orgSettings/PrnSettings'))
const PrnPermissions = lazy(() => import('@components/orgSettings/PrnPermissions'))
const PrnReasonRequired = lazy(() => import('@components/orgSettings/PrnReasonRequired'))
const Tasks = lazy(() => import('@components/orgSettings/Tasks'))
const ConsumptionOverrides = lazy(() => import('@components/orgSettings/ConsumptionOverrides'))
const ConsumptionThresholds = lazy(() => import('@components/orgSettings/ConsumptionThresholds'))
const IAM = lazy(() => import('@components/orgSettings/iam'))
const Security = lazy(() => import('@components/orgSettings/Security'))
const InactivityLock = lazy(() => import('@components/orgSettings/InactivityLock'))
const NarcoticCounts = lazy(() => import('@components/orgSettings/NarcoticCounts'))

const getPanelsConfig = (query, organization, canManageSsoForOrg, statusMessage) => {
  const {
    impactCheckTime,
    staffCanCustomizeNotifications,
    isLocalAuthRequired,
    requireDoseBarcodeScanning,
    prnPermissionRequired,
    prnReasonRequired,
    impactChecksEnabled,
    bowelMovementTrackingEnabled,
    consumptionOverrideAllowed,
    patientLabel,
    caregiverLabel,
    adminLabel,
    adminLabelPlural,
    patientLabelPlural,
    caregiverLabelPlural,
    flexibility,
    caregiversCanLoadRefills,
    selfAdminCanLoadRefills,
    userSessionTimeoutInMinutes,
    narcoticCountsDuringMedPass,
    narcoticCountsWhenShiftChange,
    narcoticCountsDoubleConfirmation,
    narcoticCountsShiftEndWarningMinutes,
    inactivityLockEnabled,
    inactivityLockSeconds,
    workosOrganizationId,
  } = query?.data || {}

  const queryDataRoleLabels = {
    patientLabel: patientLabel || 'Patient',
    caregiverLabel: caregiverLabel || 'Caregiver',
    adminLabel: adminLabel || 'Admin',
    adminLabelPlural: adminLabelPlural || 'Admins',
    patientLabelPlural: patientLabelPlural || 'Patients',
    caregiverLabelPlural: caregiverLabelPlural || 'Caregivers',
  }

  const queryDataInventory = {
    caregiversCanLoadRefills: caregiversCanLoadRefills?.value || false,
    selfAdminCanLoadRefills: selfAdminCanLoadRefills?.value || false,
  }

  const flexibilityValues = Object.keys(flexibility || {}).reduce((acc, key) => {
    acc[`flexibilityAttributes[${key}]`] = flexibility[key]?.value || ''
    return acc
  }, {})

  return [
    {
      header: 'General',
      content: [
        {
          id: 'role-labels',
          component: RoleLabels,
          props: {
            queryDataRoleLabels,
          },
          currentOrgData: queryDataRoleLabels,
        },
        {
          id: 'inventory',
          component: Inventory,
          props: {
            patientLabel,
            caregiverLabel,
            queryDataInventory,
          },
          currentOrgData: queryDataInventory,
        },
      ],
      shouldShowSetting: true,
    },
    {
      header: 'PRN',
      content: [
        {
          id: 'prn-settings',
          component: PrnSettings,
          currentOrgData: {
            impactChecksEnabled: impactChecksEnabled?.value || false,
            impactCheckTime: impactCheckTime?.value || '',
          },
        },
        {
          id: 'prn-permissions',
          component: PrnPermissions,
          props: {
            permissions: prnPermissionRequired?.options,
            patientLabel,
            caregiverLabel,
          },
          currentOrgData: { prnPermissionRequired: prnPermissionRequired?.value || false },
        },
        {
          id: 'prn-reason-required',
          component: PrnReasonRequired,
          props: {
            reasonRequired: prnReasonRequired?.options,
            prnReasonRequired: prnReasonRequired?.value || false,
          },
          currentOrgData: { prnReasonRequired: prnReasonRequired?.value || false },
        },
      ],
      shouldShowSetting: true,
    },
    {
      header: 'Mobile App',
      content: [
        {
          id: 'mobile-app',
          component: MobileApp,
          props: {
            caregiverLabel,
          },
          currentOrgData: {
            staffCanCustomizeNotifications: staffCanCustomizeNotifications?.value || false,
            isLocalAuthRequired: isLocalAuthRequired?.value || false,
            requireDoseBarcodeScanning: requireDoseBarcodeScanning?.value || false,
          },
        },
      ],
      shouldShowSetting: true,
    },
    {
      header: 'Tasks',
      content: [
        {
          id: 'tasks',
          component: Tasks,
          props: { patientLabel },
          currentOrgData: {
            bowelMovementTrackingEnabled: bowelMovementTrackingEnabled?.value || false,
          },
        },
        {
          id: 'narcotic-counts',
          component: NarcoticCounts,
          currentOrgData: {
            narcoticCountsDoubleConfirmation: narcoticCountsDoubleConfirmation?.value || false,
            narcoticCountsWhenShiftChange: narcoticCountsWhenShiftChange?.value || false,
            narcoticCountsDuringMedPass: narcoticCountsDuringMedPass?.value || false,
            narcoticCountsShiftEndWarningMinutes: narcoticCountsShiftEndWarningMinutes?.value || '',
          },
          shouldShowContent: organization?.narcoticCountsEnabled,
        },
      ],
      shouldShowSetting: true,
    },
    {
      header: 'Consumption',
      content: [
        {
          id: 'consumption-overrides',
          component: ConsumptionOverrides,
          props: {
            consumptionOverrides: consumptionOverrideAllowed?.options,
            patientLabel,
            caregiverLabel,
          },
          currentOrgData: {
            consumptionOverrideAllowed: consumptionOverrideAllowed?.value || false,
          },
        },
        {
          id: 'consumption-thresholds',
          component: ConsumptionThresholds,
          props: {
            flexibility,
          },
          currentOrgData: flexibilityValues,
        },
      ],
      shouldShowSetting: true,
    },
    {
      header: 'IAM',
      content: [
        {
          id: 'iam',
          component: IAM,
          props: {
            organization,
            workosOrganizationId,
            statusMessage,
          },
        },
      ],
      showSubmitButton: false,
      shouldShowSetting: canManageSsoForOrg,
    },
    {
      header: 'Security',
      content: [
        {
          id: 'security',
          component: Security,
          currentOrgData: { userSessionTimeoutInMinutes: userSessionTimeoutInMinutes?.value || '' },
        },
        {
          id: 'inactivity-lock',
          component: InactivityLock,
          currentOrgData: {
            inactivityLockEnabled: inactivityLockEnabled?.value || false,
            inactivityLockSeconds: inactivityLockSeconds?.value || '',
          },
          shouldShowContent: organization?.usernamePasswordAuthEnabled,
        },
      ],
      shouldShowSetting: true,
    },
  ]
}

export default getPanelsConfig
