import React from 'react'
import RequirementCheckItem from '@components/display/RequirementCheckItem'

function RequirementsTooltip({ value }) {
  const lengthRequirement = value?.length >= 6

  return (
    <div>
      <ul className="text-xs">
        <RequirementCheckItem requirement="At least 6 characters" isMet={lengthRequirement} />
      </ul>
    </div>
  )
}

export default RequirementsTooltip
