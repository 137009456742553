import React, { useRef } from 'react'
import {
  Routes, Route, useLocation,
} from 'react-router-dom'
import { withApp } from '@components/App'
import { Messages } from 'primereact/messages'
import { Card } from 'primereact/card'
import CreateOrganization from '../Organizations/CreateOrganization'
import PageHeader from './PageHeader'
import UpdateOrganization from '../Organizations/UpdateOrganization'

function OrgSettings({ timezones }) {
  const statusMessage = useRef(null)
  const { pathname } = useLocation()

  const isCreateOrganization = pathname === '/admin/organizations/new'

  return (
    <>
      <PageHeader isCreateOrganization={isCreateOrganization} />
      <Card>
        <Messages ref={statusMessage} />
        <Routes>
          <Route
            path="/admin/organizations/new"
            element={(
              <CreateOrganization
                timezones={timezones}
                statusMessage={statusMessage}
              />
            )}
          />
          <Route
            path="/admin/organizations/:id/edit/*"
            element={<UpdateOrganization statusMessage={statusMessage} timezones={timezones} />}
          />
        </Routes>
      </Card>
    </>
  )
}

export default withApp(OrgSettings)
