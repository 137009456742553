import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response } from '@services/utils'

export default function useCreateReportLateDoses(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['reportLateDoses'],
    mutationFn: async ({ month, year }) => {
      const response = await ApiClient.post('/reports/late_doses', {
        month,
        year,
        patientId,
      })
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
