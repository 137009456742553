import React from 'react'
import { Tag } from 'primereact/tag'

function ActivationTag({ activated }) {
  return (
    <Tag
      className="w-5rem"
      rounded={false}
      value={activated ? 'Activated' : 'Deactivated'}
      style={{ background: activated ? 'var(--green-400)' : 'var(--bluegray-200)' }}
    />
  )
}

export default ActivationTag
