import { useMemo } from 'react'
import { map } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { dateFromHoursMinutes } from '@services/utils/time'

export function useEventTimesMutation({ ownerId, ownerType, isCreate }) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['eventTimes', ownerId, ownerType],
    mutationFn: (eventTime) => {
      const payload = { eventTime: { ...eventTime, ownerId, ownerType } }
      if (isCreate) {
        return ApiClient.post('/event_times', payload)
      }
      return ApiClient.patch(`/event_times/${eventTime.id}`, payload)
    },
    onSuccess: () => queryClient.invalidateQueries('eventTimes'),
  })
}

export function useEventTimes({ patientId, siteId, organizationId }) {
  let requestUrl = `/patients/${patientId}/event_times`

  if (siteId) {
    requestUrl = `/sites/${siteId}/event_times`
  } else if (organizationId) {
    requestUrl = `/organizations/${organizationId}/event_times`
  }

  const query = useQuery({
    queryKey: ['eventTimes', organizationId, requestUrl],
    queryFn: () => ApiClient.get(requestUrl),
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (data) => ({
      ...data,
      startTimeMinutes: query.data.startTime,
      endTimeMinutes: query.data.endTime,
      startTime: dateFromHoursMinutes(data.startTimeHours, data.startTimeMinutes),
      endTime: dateFromHoursMinutes(data.endTimeHours, data.endTimeMinutes),
    })),
  }), [query.data])
}

export function useEventTime(eventTimeId) {
  const query = useQuery({
    queryKey: ['eventTime', eventTimeId],
    queryFn: () => ApiClient.get(`/event_times/${eventTimeId}`),
    enabled: !!eventTimeId,
  })

  return useMemo(() => {
    if (!query.data) {
      return query
    }
    return {
      ...query,
      data: {
        ...query.data,
        startTimeMinutes: query.data.startTime,
        endTimeMinutes: query.data.endTime,
        startTime: dateFromHoursMinutes(
          query.data.startTimeHours,
          query.data.startTimeMinutes,
        ),
        endTime: dateFromHoursMinutes(
          query.data.endTimeHours,
          query.data.endTimeMinutes,
        ),
      },
    }
  }, [query.data])
}
