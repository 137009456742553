import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useReactivateCaregiver(statusMessage, caregiverLabel) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['reactivateCaregiver'],
    mutationFn: ({ userId, siteIds, note }) => (
      ApiClient.post(`/caregivers/${userId}/reactivate`, { siteIds, note })
    ),
    onSuccess: async () => {
      statusMessage.current.show([
        { severity: 'success', summary: `${caregiverLabel} Reactivated!` },
      ])
      await queryClient.invalidateQueries('caregivers')
      await queryClient.invalidateQueries('deactivatedCaregivers')
    },
    onError: () => {
      statusMessage.current.show([
        { severity: 'danger', summary: `Error while Reactivating ${caregiverLabel}.` },
      ])
    },
  })
}

export default useReactivateCaregiver
