import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Messages } from 'primereact/messages'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'
import { exactSearch } from '@services/utils/search'
import { formatDateTimeLocale } from '@services/utils'

function DeactivatedUsers({
  statusMessage,
  users,
  isLoading,
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  reactivateUser,
  isReactivateUserLoading,
  reactivatedUserId,
  setReactivatedUserId,
  rootPath,
  avatarThumbnail,
  showNoteColumn,
  showReactivateDialog,
  reactivateDialog,
  setReactivateDialogVisible,
  footer,
}) {
  const navigate = useNavigate()
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredUsers(exactSearch(users, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredUsers(users)
    }
    setSearchLoading(false)
  }, [setSearchLoading, users, isLoading, searchTerm])

  const handleReactivate = async (userId, siteIds, note) => {
    await reactivateUser({ userId, siteIds, note }, {
      onSuccess: () => {
        if (showReactivateDialog) setReactivateDialogVisible(false)

        setReactivatedUserId(null)
        navigate(`${rootPath}/${userId}`)
      },
    })
  }

  const reactivateButton = ({ id, isDirectoryManaged }) => (
    <Button
      className="p-button-sm"
      label="Reactivate"
      loading={reactivatedUserId === id && isReactivateUserLoading}
      disabled={isDirectoryManaged}
      onClick={() => {
        setReactivatedUserId(id)

        if (showReactivateDialog) {
          setReactivateDialogVisible(true)
        } else {
          handleReactivate(id)
        }
      }}
    />
  )

  const formatDirectorySync = ({ isDirectoryManaged }) => {
    if (!isDirectoryManaged) return

    return (<UserDirectoryServiceIcon service="active-directory" tooltipPosition="left" />)
  }

  return (
    <div>
      <Messages ref={statusMessage} />
      { showReactivateDialog && reactivateDialog(handleReactivate) }
      <div className="flex flex-column gap-3 text-base text-900">
        <DataTable
          dataKey="id"
          loading={isLoading}
          value={filteredUsers}
          selectionMode="single"
          onSelectionChange={(e) => navigate(`${rootPath}/${e.value.id}`)}
          sortField="lastName"
          sortOrder={1}
          rowClassName="fadein caregiver-row"
          scrollable
          scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
        >
          { avatarThumbnail && (
            <Column headerStyle={{ width: '3rem' }} body={avatarThumbnail} />
          )}
          <Column header="First Name" field="firstName" />
          <Column header="Last Name" field="lastName" sortable />
          { showNoteColumn && (
            <Column header="Note" field="currentDeactivation.deactivationNote" />
          )}
          <Column
            body={(rowData) => {
              if (rowData.currentDeactivation.deactivatedAt) {
                const deactivatedAt = moment(rowData.currentDeactivation.deactivatedAt)
                return formatDateTimeLocale(deactivatedAt)
              }
            }}
            header="Deactivated"
          />
          <Column
            header=""
            body={reactivateButton}
          />
          <Column
            field="directorySync"
            body={formatDirectorySync}
          />
        </DataTable>
      </div>
      {footer(filteredUsers.length, isLoading)}
    </div>
  )
}

export default DeactivatedUsers
