import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

const useLoginWithUsernamePassword = (statusMessage) => useMutation({
  mutationKey: ['loginWithUsernamePassword'],
  mutationFn: ({ username, password }) => ApiClient.post('/auth_by_username', {
    username,
    password,
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
  onSuccess: () => {
    statusMessage.current.clear()
  },
})

const useRequestOtpMutation = (statusMessage) => useMutation({
  mutationKey: ['requestOtp'],
  mutationFn: ({ email }) => ApiClient.post('/otp', {
    email,
    client: 'web',
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
})

function useHandleLogin(statusMessage, setPasswordSetRequired, setTempToken) {
  const {
    mutateAsync: loginWithUsernamePassword,
    isLoading: isLoggingIn,
  } = useLoginWithUsernamePassword(statusMessage)
  const {
    mutateAsync: requestOtp,
    isLoading: isRequestingOtp,
  } = useRequestOtpMutation(statusMessage)

  const handleLoginWithUsernamePassword = (username, password) => {
    loginWithUsernamePassword({ username: username.trim(), password: password.trim() }, {
      onSuccess: (response) => {
        if (response.isPasswordTemporary) {
          setPasswordSetRequired(true)
          setTempToken(response.token)
        } else {
          window.location.href = '/'
        }
      },
    })
  }

  const handleRequestOtp = async (email) => {
    await requestOtp({ email: email.trim() }, {
      onSuccess: () => {
        window.location.href = '/waiting'
      },
    })
  }

  return {
    handleLoginWithUsernamePassword,
    handleRequestOtp,
    isLoggingIn,
    isRequestingOtp,
  }
}

export default useHandleLogin
