import React from 'react'
import { Card } from 'primereact/card'
import DoseCalendar from '@components/clientDoses/Schedule/DoseCalendar'
import { useCreateDoseSchedule, useDestroyDoseSchedule, useUpdateDoseSchedule } from '@hooks/schedules'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import { DoseAttributeRow } from '@components/DoseAttributeRow'
import { useUpsertDose } from '../clientDosesHooks'

function ClientDoseSchedule({
  clientId, scheduleStatusMessage, dose, timezone,
}) {
  const {
    mutateAsync: destroySchedule,
  } = useDestroyDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: createSchedule,
  } = useCreateDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: updateSchedule,
  } = useUpdateDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: upsertDose,
    isLoading: upsertDoseLoading,
  } = useUpsertDose({
    patientId: clientId,
    statusMessage: scheduleStatusMessage,
    isUpdate: true,
  })

  const handleSetPrn = async (prn) => upsertDose({ id: dose.id, prn, schedules: [] })

  const startAt = dose?.startAt && timezone
    ? moment(dose.startAt).tz(timezone).format(momentFormats.dateYear) : null

  const endAt = dose?.endAt && timezone
    ? moment(dose.endAt).tz(timezone).format(momentFormats.dateYear) : null

  return (
    <div className="col-12">
      <Card className="h-full">
        <DoseCalendar
          clientId={clientId}
          schedules={dose.schedules}
          dose={dose}
          handleSetPrn={handleSetPrn}
          handleSetPrnLoading={upsertDoseLoading}
          handleCreateSchedule={createSchedule}
          handleUpdateSchedule={updateSchedule}
          handleDestroySchedule={destroySchedule}
          showAddTimeControls={false}
        />
        <div className="mt-3">
          <DoseAttributeRow
            key="Start Date"
            label="Start Date"
            currentValue={startAt}
          />
          <DoseAttributeRow
            key="End Date"
            label="End Date"
            currentValue={endAt}
          />
        </div>
      </Card>
    </div>
  )
}

export default ClientDoseSchedule
