import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useSSOLogin = (email, loginButtonClicked) => useQuery({
  queryKey: ['sso_auth_url', email, loginButtonClicked],
  queryFn: () => ApiClient.get('/single_sign_on/login', { params: { email } }),
  enabled: loginButtonClicked && !!email,
  refetchOnWindowFocus: false,
})

export default useSSOLogin
