import React from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ActivationTag from '@components/user/ActivationTag'
import { useUserActivationsQuery } from '@hooks/userActivations'
import { formatDateTimeLocale } from '@services/utils'

function ActivationHistory({
  patient,
}) {
  const query = useUserActivationsQuery(patient?.id)
  const { userActivations = [] } = query?.data || {}
  const isLoading = query?.isLoading
  const parsedActivations = userActivations.map((userActivation) => [{
    activated: true,
    date: userActivation.createdAt,
    note: userActivation.activationNote,
    id: `${userActivation.id}-activated`,
    userId: userActivation.userId,
  }].concat(
    userActivation.deactivatedAt ? [{
      activated: false,
      date: userActivation.deactivatedAt,
      note: userActivation.deactivationNote,
      id: `${userActivation.id}-deactivated`,
      userId: userActivation.userId,
    }] : [],
  )).flat()

  return (
    <Card data-test="activation-history">
      <div className="flex flex-row justify-content-between align-items-center mb-2 text-lg">
        <span>Activations</span>
      </div>
      <DataTable
        dataKey="id"
        className="fadein"
        loading={isLoading}
        sortField="date"
        sortOrder={-1}
        sortMode="single"
        value={parsedActivations}
      >
        <Column
          className="w-3"
          header="Date"
          field="date"
          body={({ date }) => formatDateTimeLocale(date, patient.timezone)}
        />
        <Column
          className="w-2"
          header="Event"
          body={ActivationTag}
        />
        <Column className="w-4" header="Note" field="note" />
      </DataTable>
    </Card>
  )
}

export default ActivationHistory
