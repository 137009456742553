import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { buildAvailableTimes } from '@components/clientDoses/doseUtils'

function CustomizeSchedules({ schedules, handleCustomizeStartTime, handleCustomizeEndTime }) {
  const availableTimes = buildAvailableTimes(schedules)

  const [customizedStartTime, setCustomizedStartTime] = useState()
  const [customizedEndTime, setCustomizedEndTime] = useState()

  const updateCustomizedStartTime = ({ value }) => {
    setCustomizedStartTime(value)
    handleCustomizeStartTime(value)
  }

  const updateCustomizedEndTime = ({ value }) => {
    setCustomizedEndTime(value)
    handleCustomizeEndTime(value)
  }

  if (availableTimes.length < 2) {
    return null
  }

  return (
    <div>
      <div className="flex align-items-center mt-3">
        Start the schedule with the
        <Dropdown
          showClear
          className="mx-2"
          value={customizedStartTime}
          options={availableTimes}
          onChange={updateCustomizedStartTime}
        />
        administration time
      </div>
      <div className="flex align-items-center mt-3">
        End the schedule with the
        <Dropdown
          showClear
          className="mx-2"
          value={customizedEndTime}
          options={availableTimes}
          onChange={updateCustomizedEndTime}
        />
        administration time
      </div>
    </div>
  )
}

export default CustomizeSchedules
