import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import { generateMonthYearsDropdownOptions } from '@services/utils/moment'

const optionToMoment = (option) => moment(`${option.year}-${option.month}-01`, 'YYYY-MM-DD')

function MonthlyRangeExportDialog({
  patientId,
  isVisible,
  setIsVisible,
  exportMutation,
  dialogTitle,
  rangeStart = moment().subtract(1, 'year'),
  rangeEnd = moment(),
}) {
  const organization = useCurrentOrganization()
  const messages = useRef()
  const {
    mutateAsync: exportData,
    isLoading: isExporting,
  } = exportMutation(messages, patientId)

  const monthYearsMenuOptions = generateMonthYearsDropdownOptions(rangeStart, rangeEnd)
  const [selectedMonthYearStart, setSelectedMonthYearStart] = useState(monthYearsMenuOptions[0])
  const [selectedMonthYearEnd, setSelectedMonthYearEnd] = useState(monthYearsMenuOptions[0])

  const handleExport = async () => {
    exportData({
      startYear: selectedMonthYearStart.year,
      startMonth: selectedMonthYearStart.month,
      endYear: selectedMonthYearEnd.year,
      endMonth: selectedMonthYearEnd.month,
    }, {
      onSuccess: () => {
        setIsVisible(false)
      },
    })
  }

  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} ${dialogTitle}`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={messages} />
      <div className="flex flex-column text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal w-2">From:</span>
          <Dropdown
            value={selectedMonthYearStart}
            options={monthYearsMenuOptions}
            onChange={(e) => {
              setSelectedMonthYearStart(e.value)
              if (optionToMoment(e.value).isAfter(optionToMoment(selectedMonthYearEnd))) {
                setSelectedMonthYearEnd(e.value)
              }
            }}
            placeholder="Select a Month"
            className="flex-grow-1"
          />
        </div>
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal w-2">To:</span>
          <Dropdown
            value={selectedMonthYearEnd}
            options={monthYearsMenuOptions}
            onChange={(e) => {
              setSelectedMonthYearEnd(e.value)
              if (optionToMoment(e.value).isBefore(optionToMoment(selectedMonthYearStart))) {
                setSelectedMonthYearStart(e.value)
              }
            }}
            placeholder="Select a Month"
            className="flex-grow-1"
          />
        </div>
        <Button
          className="p-button-sm p-button-outlined"
          label="Export"
          onClick={handleExport}
          loading={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default MonthlyRangeExportDialog
