import React, { useState, useEffect, useRef } from 'react'
import {
  compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { ProfileForm, SettingsForm } from '@components/admins/AdminForm'
import ElectronicSignature from '@components/user/ElectronicSignature'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import {
  useSignature,
  useCreateSignature,
} from '@components/user/ElectronicSignature/signatureHooks'
import { Menu } from 'primereact/menu'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import {
  useReactivateAdmin,
  useUpdateAdminMutation,
  useUserTitleOptions,
  useSetUserTitleMutation,
  useAdminDeactivateMutation,
} from './adminsHooks'

function buildMenuItems({
  admin, currentOrganization, handleReactivate, handleDeactivate,
}) {
  const items = []

  if (!admin || admin.isDirectoryManaged) {
    return items
  }

  if (admin?.isActive) {
    items.push({
      label: 'Deactivate',
      icon: 'pi pi-times',
      command: () => confirmDialog({
        message: `Are you sure you want to deactivate this ${currentOrganization.adminLabelSingular}?`,
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => handleDeactivate(),
      }),
    })
  } else {
    items.push({
      label: 'Reactivate',
      icon: 'pi pi-check',
      command: () => confirmDialog({
        message: `Are you sure you want to reactivate this ${currentOrganization.adminLabelSingular}?`,
        header: 'Confirm',
        icon: 'pi pi-check',
        accept: () => handleReactivate(),
      }),
    })
  }

  return items
}

function UpdateAdmin({ admin = {}, handleSetHeader }) {
  const adminActionMenu = useRef(null)
  const statusMessage = useRef(null)
  const { adminId } = useParams()
  const currentUser = useCurrentUser()
  const isSameUser = adminId.toString() === currentUser.id.toString()
  const organization = useCurrentOrganization()
  const navigate = useNavigate()

  const {
    email,
    id,
    initials,
    phone,
    firstName,
    lastName,
    customerId,
    isDirectoryManaged,
    title: adminTitle,
    ccmEligible = false,
    adminSettings: {
      canApprovePermissionRequests = false,
      shouldReceivePharmacyOrderNotifications = false,
      shouldReceivePendingRefillsNotifications = false,
    } = {},
    ssoAdminAccess = false,
  } = admin

  const [profile, setProfile] = useState({
    email,
    id,
    initials,
    phone,
    firstName,
    lastName,
    customerId,
    isDirectoryManaged,
  })
  const [title, setTitle] = useState(adminTitle)
  const [settings, setSettings] = useState({
    ccmEligible,
    canApprovePermissionRequests,
    shouldReceivePharmacyOrderNotifications,
    shouldReceivePendingRefillsNotifications,
    ssoAdminAccess,
  })
  const [signature, setSignature] = useState()
  const [isEditingSignature, setIsEditingSignature] = useState(false)

  const {
    mutateAsync: updateAdmin,
    isLoading: updateIsLoading,
  } = useUpdateAdminMutation(adminId, statusMessage)

  const {
    data: titleOptions,
    isLoading: isTitleOptionsLoading,
  } = useUserTitleOptions()

  const {
    mutateAsync: setUserTitle,
  } = useSetUserTitleMutation(adminId, statusMessage)

  const {
    data: userSignature,
  } = useSignature({ userId: adminId, enabled: isSameUser, statusMessage })

  const {
    mutateAsync: createSignature,
    isLoading: createSignatureIsLoading,
  } = useCreateSignature(adminId, statusMessage)

  const {
    mutateAsync: deactivateAdmin,
  } = useAdminDeactivateMutation(statusMessage, organization.adminLabelSingular)

  const {
    mutateAsync: reactivateAdmin,
  } = useReactivateAdmin(statusMessage, organization.adminLabelSingular)

  const handleUpdateProfile = () => {
    updateAdmin({ ...profile }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Profile updated',
        })
      },
    })
  }

  const handleUpdateSettings = async (newSettings) => {
    const adminSettingsAttributes = {
      shouldReceivePharmacyOrderNotifications: newSettings?.shouldReceivePharmacyOrderNotifications,
      canApprovePermissionRequests: newSettings?.canApprovePermissionRequests,
      shouldReceivePendingRefillsNotifications:
        newSettings?.shouldReceivePendingRefillsNotifications,
    }
    await updateAdmin({ ...newSettings, adminSettingsAttributes }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Settings updated',
        })
      },
    })
    setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }))
  }

  const handleSetUserTitle = (value) => {
    setUserTitle(value)
  }

  const handleCreateSignature = async () => {
    createSignature(signature, {
      onSuccess: () => {
        setIsEditingSignature(false)
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Signature updated',
        })
      },
    })
  }

  const handleDeactivate = async () => {
    await deactivateAdmin(adminId)
    navigate(`/admin/admins/${adminId}`)
  }

  const handleReactivate = async () => {
    await reactivateAdmin(adminId)
    navigate(`/admin/admins/${adminId}`)
  }

  const breadcrumbs = compact([
    {
      label: 'Admin',
      template: <Link to={`/admin/admins/${adminId}`}>{organization.adminLabelSingular}</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ])

  const canUpdate = () => {
    if (currentUser.role === 'superadmin') return true
    return currentUser.role === 'site_admin' && currentUser.id !== Number(adminId)
  }

  const menuItems = buildMenuItems({
    admin,
    currentUserRole: currentUser?.role,
    handleReactivate,
    handleDeactivate,
    currentOrganization: organization,
  })

  const primaryAction = canUpdate() && menuItems.length !== 0 ? (
    <>
      <Menu
        model={menuItems}
        popup
        ref={adminActionMenu}
        id="adminActionMenu"
      />
      <Button
        label="Actions"
        className="p-button-sm"
        icon="pi pi-chevron-down"
        onClick={(event) => adminActionMenu.current.toggle(event)}
      />
    </>
  ) : null

  const signatureTitle = () => {
    if (isEditingSignature) {
      return 'Electronic Signature'
    }
    return (
      <div className="flex flex-row justify-content-between align-items-baseline w-full">
        <span>Electronic Signature</span>
        <Button
          label="Edit Signature"
          className="p-button-sm p-button-text py-1"
          onClick={() => setIsEditingSignature(true)}
        />
      </div>
    )
  }

  useEffect(() => {
    handleSetHeader({ breadcrumbs, primaryAction })
  }, [admin])

  useEffect(() => {
    if (admin && titleOptions) {
      setTitle(titleOptions.find((t) => t.abbreviation === admin?.title?.title))
    }
  }, [admin, titleOptions])

  useEffect(() => {
    if (userSignature) {
      setSignature(userSignature.svg)
      setIsEditingSignature(false)
    } else {
      setIsEditingSignature(true)
    }
  }, [userSignature])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <ConfirmDialog />
      <div className="col-6">
        <FormWrapper title="Profile" fullHeight>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Profile"
            className="p-button-sm mt-4"
            disabled={isEqual(profile, pick(admin, keys(profile)))}
            loading={updateIsLoading}
            onClick={handleUpdateProfile}
          />
        </FormWrapper>
      </div>
      <div className="col-6 flex flex-column gap-3">
        <FormWrapper title="Title">
          <Dropdown
            value={title}
            options={titleOptions}
            dataKey="abbreviation"
            optionLabel="title"
            onChange={(e) => handleSetUserTitle(e.value.abbreviation)}
            placeholder="Select a title"
            loading={isTitleOptionsLoading || updateIsLoading}
            disabled={updateIsLoading}
            className="w-full"
            filter
          />
        </FormWrapper>
        <FormWrapper title="Settings">
          <SettingsForm
            settings={settings}
            setSettings={handleUpdateSettings}
            loading={updateIsLoading}
          />
        </FormWrapper>
        {isSameUser
          && (
          <FormWrapper title={signatureTitle()}>
            <ElectronicSignature
              isEditingSignature={isEditingSignature}
              isViewingCurrentUser={isSameUser}
              signature={signature}
              setSignature={setSignature}
            />
            {isEditingSignature
              && (
              <Button
                label="Save Signature"
                className="p-button-sm mt-2"
                loading={createSignatureIsLoading}
                onClick={handleCreateSignature}
              />
              )}
          </FormWrapper>
          )}
      </div>
    </div>
  )
}

export default UpdateAdmin
