import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import RequirementsTooltip from '@components/user/RequirementsTooltip'

function PasswordInput({ user, onSubmit, onClear }) {
  const [showPassword, setShowPassword] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [newPassword, setPassword] = useState('')

  if (!user) {
    return null
  }

  const isPermanentPassword = user.hasPassword
  const isPasswordValid = () => newPassword.length >= 6
  const isSameAsOldTempPassword = () => user.temporaryPassword === newPassword
  const buttonClass = classNames(
    'p-button-sm',
    'p-button-text',
    'p-button-success',
    { 'disabled-button': !isPasswordValid() || isSameAsOldTempPassword() },
  )

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
    onSubmit(newPassword)
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  const handleClear = () => {
    onClear()
  }

  if (isPermanentPassword) {
    return (
      <div className="flex flex-row align-items-center">
        <span className="text-sm mr-2">*********</span>
        <Button
          onClick={() => confirmDialog({
            message:
              'This password was already set by the user. Are you sure you want to remove it?',
            header: 'Remove Password',
            acceptClassName: 'p-button-danger',
            accept: handleClear,
          })}
          className="p-button-sm p-button-text p-button-danger"
          icon="pi pi-eraser"
          tooltipOptions={{ position: 'top' }}
        />
      </div>
    )
  }

  if (isEditing) {
    return (
      <div className="flex flex-row p-grid align-items-center">
        <Tooltip target=".password-input" position="top">
          <RequirementsTooltip value={newPassword} />
        </Tooltip>
        <div className="p-col">
          <InputText
            className="p-inputtext-sm block password-input"
            style={{ width: '100%', height: '42px', borderRadius: 6 }}
            placeholder="password"
            value={newPassword}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
          />
        </div>
        <div className="p-col-fixed">
          <Button
            onClick={handleSave}
            className={buttonClass}
            icon="pi pi-check"
            disabled={!isPasswordValid() || isSameAsOldTempPassword()}
            tooltip="The new password must be different from the old one"
            tooltipOptions={{ showOnDisabled: true }}
          />
          <Button
            onClick={handleCancel}
            className="p-button-sm p-button-text p-button-secondary"
            icon="pi pi-times"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-row align-items-center">
      <div className="p-col">
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {user.temporaryPassword ? (
            <>
              {showPassword ? (
                <span className="text-sm mr-2">
                  {user.temporaryPassword}
                </span>
              ) : (
                <span className="text-sm mr-2">*********</span>
              )}
              <Tag
                className="text-small font-normal"
                style={{ background: 'var(--orange-500)' }}
                value="Temporary"
              />
              <Button
                onClick={() => setShowPassword(!showPassword)}
                className="p-button-sm p-button-text p-button-secondary"
                icon={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
              />
            </>
          ) : (
            <span className="text-sm">No password set</span>
          )}
        </div>
      </div>
      <div className="p-col-fixed">
        <Button
          onClick={handleEdit}
          className="p-button-sm p-button-text p-button-secondary"
          icon="pi pi-pencil"
        />
      </div>
    </div>
  )
}

export default PasswordInput
