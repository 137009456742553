import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response } from '@services/utils'

export default function useCreateReportNarcoticCounts(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['reportNarcoticCounts'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.post('/reports/narcotic_counts', {
        year,
        month,
        patientId,
      })
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
