import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response } from '@services/utils'

export default function useCreateReportPhysicianOrders(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['reportPhysiciansOrders'],
    mutationFn: async (params) => {
      const response = await ApiClient.post('/reports/physicians_orders', {
        patientId,
        startMonth: params.startMonth,
        startYear: params.startYear,
        endMonth: params.endMonth,
        endYear: params.endYear,
      })
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
