import React, { useState } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import PasswordWithLabel from '@components/display/PasswordWithLabel'
import RequirementsTooltip from './RequirementsTooltip'

function SetNewPasswordForm({ onSetNewPassword, isLoading }) {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const isPasswordValid = (text) => {
    const lengthRequirement = text?.length >= 10
    const includeUppercase = /[A-Z]/.test(text)
    const includeLowercase = /[a-z]/.test(text)
    const includeNumber = /[0-9]/.test(text)
    const includeSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(text)
    const passwordMatch = text === passwordConfirmation
    return (
      lengthRequirement
        && includeUppercase
        && includeLowercase
        && includeNumber
        && includeSpecial
        && passwordMatch
    )
  }

  return (
    <div id="login-form">
      <img alt="logo" src="/logo.svg" className="login-logo" />
      <h2 id="title">You need to set a new Password!</h2>
      <Tooltip target=".password-input" position="top">
        <RequirementsTooltip password={password} passwordConfirmation={passwordConfirmation} />
      </Tooltip>
      <div className="field">
        <PasswordWithLabel
          label="Password"
          name="password"
          id="set-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          inputTextClassName="password-input"
          toggleMask
        />
      </div>
      <div className="field">
        <PasswordWithLabel
          inputTextClassName="password-input"
          label="Password Confirmation"
          name="passwordConfirmation"
          id="set-password-confirmation"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          toggleMask
        />
      </div>
      <div className="field text-center">
        <Button
          label="Login"
          className="p-button-filled p-button-info"
          disabled={
              password.length === 0
              || passwordConfirmation.length === 0
              || !isPasswordValid(password)
            }
          onClick={() => {
            onSetNewPassword(password)
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export default SetNewPasswordForm
