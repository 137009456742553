import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { DataView } from 'primereact/dataview'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import { useCreateReportClinicalNotes } from '@hooks/reports'
import {
  usePatientClinicalNotes,
} from './clinicalNotesListHooks'
import CreateClinicalNoteDialog from './CreateClinicalNoteDialog'
import ClinicalNoteCard from './ClinicalNoteCard'
import MonthlyExportDialog from '../MontlyExportDialog'

function ClinicalNotesList() {
  const statusMessage = useRef()
  const { patientId } = useParams()
  const [isCreationDialogVisible, setIsCreationDialogVisible] = useState(false)
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [filteredNotes, setFilteredNotes] = useState([])

  const {
    data: clinicalNotes,
    isLoading,
  } = usePatientClinicalNotes(patientId, statusMessage)

  useEffect(() => {
    if (searchTerm) {
      setFilteredNotes(exactSearch(clinicalNotes, ['note', 'author.fullName'], searchTerm))
    } else {
      setFilteredNotes(clinicalNotes)
    }
  }, [clinicalNotes, searchTerm])

  const clinicalNoteTemplate = (props) => (
    <ClinicalNoteCard statusMessage={statusMessage} {...props} />
  )

  return (
    <>
      <CreateClinicalNoteDialog
        visible={isCreationDialogVisible}
        setVisible={setIsCreationDialogVisible}
        patientId={patientId}
        statusMessage={statusMessage}
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={isExportDialogVisible}
        setIsVisible={setIsExportDialogVisible}
        exportMutation={useCreateReportClinicalNotes}
        dialogTitle="Clinical Notes"
      />
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-2 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center px-1">
          <SearchField
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder="Search by content or author"
          />
          <div className="flex flex-row gap-2">
            <Button
              label="Export"
              className="p-button-sm p-button-outlined"
              onClick={() => setIsExportDialogVisible(true)}
            />
            <Button
              label="Add Note"
              className="p-button-sm"
              onClick={() => setIsCreationDialogVisible(true)}
            />
          </div>
        </div>
        {
          isLoading
            && (
            <div className="p-m-4">
              Loading...
            </div>
            )
        }
        {
          !isLoading
            && (
              <DataView
                layout="list"
                value={filteredNotes}
                itemTemplate={clinicalNoteTemplate}
              />
            )
          }
      </div>
    </>
  )
}

export default ClinicalNotesList
