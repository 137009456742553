import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useSetTemporaryPassword = (statusMessage, userRole) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['useSetTemporaryPassword'],
    mutationFn: ({ userId, temporaryPassword }) => ApiClient.post(`/users/${userId}/set_temporary_password`, { temporaryPassword }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userRole)
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Temporary password has been updated.',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useSetTemporaryPassword
