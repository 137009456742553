import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useRefreshFacility = (statusMessage) => useMutation({
  mutationFn: (integrationFacilityId) => ApiClient.post('/drx/refresh_facility', {
    integrationFacilityId,
  }),
  onError: (error) => {
    displayErrorMessages(error, statusMessage)
  },
})

export default useRefreshFacility
