import React from 'react'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { classNames } from 'primereact/utils'
import { dateTemplate, timeTemplate, isBeforeNow } from '@services/utils'
import ExpiryCountdownLabel from '../display/ExpiryCountdownLabel'

function ItemAttribute({
  title, details, loading, width = '25%',
}) {
  return (
    <div className="flex align-items-start px-1 pt-1" style={{ width }}>
      <div>
        <span style={{ wordBreak: 'break-all' }} className="text-900 font-medium block mb-2">
          {loading && <Skeleton className="inline-flex" width="8rem" height="1.2rem" />}
          {!loading && title}
        </span>
        {loading && Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="flex text-700 mb-2">
            <Skeleton className="inline-flex" width="12rem" height="1rem" />
          </div>
        ))}
        {!loading && details.map((detail) => {
          const iconClassNames = classNames({
            'pi pi-thumbs-up ml-1': detail?.includes('Effective'),
            'pi pi-thumbs-down ml-1': detail?.includes('Ineffective'),
          })

          return (
            <div key={detail} className="flex text-700 mb-2">
              {detail}
              {iconClassNames && <div><i className={iconClassNames} /></div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const getDefaultActionView = (action, extraClassNames = '') => (
  <Button
    key={action.label}
    label={action.label}
    onClick={action.command}
    loading={action.loading}
    icon={action.icon}
    className={`${action.className || 'p-button-sm p-button-text'} ${extraClassNames}`}
  />
)

const getHighRiskDosesActionView = (action, expiryDate) => {
  const isExpired = isBeforeNow(expiryDate)
  return (
    <div className="flex flex-column gap-2 w-13rem h-6rem align-items-start justify-content-center">
      {isExpired ? (
        <>
          <span>{`Expired at ${timeTemplate(action.expiryDate)}`}</span>
          <span>{dateTemplate(action.expiryDate)}</span>
        </>
      ) : (
        <>
          {getDefaultActionView(action, 'p-1')}
          <ExpiryCountdownLabel expiryDate={expiryDate} />
        </>
      )}
    </div>
  )
}

function FeedItem({
  attributes,
  actions,
  key,
  loading,
}) {
  return (
    <li className="py-3 border-bottom-1 surface-border flex flex-row align-items-start justify-content-between">
      {/* eslint-disable-next-line react/no-array-index-key */}
      {loading && Array.from({ length: 4 }).map((_, index) => <ItemAttribute key={index} width="25%" loading />)}
      {!loading
        && attributes.map(({ title, details, width }) => (
          <ItemAttribute key={title} title={title} details={details} width={width} />
        ))}
      {actions
        && (
          <div className="flex flex-column ml-auto">
            {actions.map((action) => (
              <div key={`action-view-${key}`} className="flex w-13rem align-items-center justify-content-center">
                {
                  (action.actionType === 'HighRiskDose')
                    ? getHighRiskDosesActionView(action, action.expiryDate)
                    : getDefaultActionView(action)
                }
              </div>
            ))}
          </div>
        )}
    </li>
  )
}

function ActivityFeed({ items, maxDataViewHeight, loading }) {
  return (
    <ul style={{ height: `${maxDataViewHeight - 25}px` }} className="list-none p-0 m-0 overflow-scroll">
      {/* eslint-disable-next-line react/no-array-index-key */}
      {loading && Array.from({ length: 3 }).map((_, index) => <FeedItem key={index} loading />)}
      {!loading
        && items.map(({ attributes, actions, key }) => (
          <FeedItem key={key} attributes={attributes} actions={actions} />
        ))}
    </ul>
  )
}

export default ActivityFeed
