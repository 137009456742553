import React, { useState, useRef, useEffect } from 'react'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import {
  useCreateReportLateDoses,
  useCreateReportMedicationErrors,
  useCreateReportTar,
  useCreateReportClinicalNotes,
  useCreateReportBowelMovements,
  useCreateReportPrnMar,
  useCreateReportMar,
  useCreateReportNarcoticCounts,
  useCreateReportPhysiciansOrders,
} from '@hooks/reports'
import moment from 'moment'
import MonthlyRangeExportDialog from '@components/patients/MontlyRangeExportDialog'
import VitalSignsReportDialog from './VitalSignsReportDialog'
import MedicationReleaseReportDialog from './MedicationReleaseReportDialog'
import MonthlyExportDialog from './MontlyExportDialog'

function Exports({
  patientId,
  exportsMenuSetupCallBack,
}) {
  const [visibleDialog, setVisibleDialog] = useState()
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()
  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const menu = useRef(null)

  const actions = [
    { label: 'MAR', command: () => setVisibleDialog('mar') },
    { label: 'TAR', command: () => setVisibleDialog('tar') },
    { label: 'PRN MAR', command: () => setVisibleDialog('prnMar') },
    { label: 'Medication Errors Report', command: () => setVisibleDialog('medicationErrorsReport') },
    { label: 'Clinical Notes Report', command: () => setVisibleDialog('clinicalNotesReport') },
    { label: 'Physician Orders', command: () => setVisibleDialog('physicianOrders') },
    { label: 'Bowel Movements Report', command: () => setVisibleDialog('bowelMovementsReport') },
    ...(organization.narcoticCountsEnabled ? [{ label: 'Narcotic Counts', command: () => setVisibleDialog('narcoticCounts') }] : []),
    { label: 'Vital Signs', command: () => setVisibleDialog('vitalSigns') },
    { label: 'Medication Release', command: () => setVisibleDialog('medicationRelease') },
    { label: 'Late Doses', command: () => setVisibleDialog('lateDoses') },
  ]

  const primaryAction = isCurrentUserAdmin && (
    <div className="flex flex-row gap-2">
      <Menu model={actions} popup ref={menu} id="popup_menu" />
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-file-export"
        onClick={(event) => menu.current.toggle(event)}
        label="Exports"
      />
    </div>
  )

  useEffect(() => {
    exportsMenuSetupCallBack(primaryAction)
  }, [])

  return (
    <>
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'mar'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportMar}
        dialogTitle="MAR"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'tar'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportTar}
        dialogTitle="TAR"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'prnMar'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportPrnMar}
        dialogTitle="PRN MAR"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'medicationErrorsReport'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportMedicationErrors}
        dialogTitle="Medication Errors Report"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'clinicalNotesReport'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportClinicalNotes}
        dialogTitle="Clinical Notes"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'bowelMovementsReport'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportBowelMovements}
        dialogTitle="Bowel Movements"
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'narcoticCounts'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportNarcoticCounts}
        dialogTitle="Narcotic Counts"
      />
      <MonthlyRangeExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'physicianOrders'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportPhysiciansOrders}
        dialogTitle="Physician Orders"
        rangeStart={moment()}
        rangeEnd={moment().add(3, 'months')}
      />
      <VitalSignsReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'vitalSigns'}
        setIsVisible={setVisibleDialog}
      />
      <MedicationReleaseReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'medicationRelease'}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'lateDoses'}
        setIsVisible={setVisibleDialog}
        exportMutation={useCreateReportLateDoses}
        dialogTitle="Late Doses"
      />
    </>

  )
}

export default Exports
