import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

const useSetPassword = (statusMessage) => useMutation({
  mutationKey: ['setPassword'],
  mutationFn: ({ password, token }) => ApiClient.post('/set_password', {
    password,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
})

export default useSetPassword
