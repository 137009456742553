import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError } from '@services/errorHandler'

function useCreateOrganizationMutation(statusMessage) {
  return useMutation({
    mutationKey: ['organizationCreate'],
    mutationFn: (organization) => ApiClient.post('/organizations', organization),
    onSuccess: (data) => {
      window.location.href = `/admin/organizations/${data.id}`
    },
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export default useCreateOrganizationMutation
