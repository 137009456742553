import React from 'react'

/**
 * Displays the footer for a patients table with a count of the patients.
 *
 * @param {boolean} isLoading - Indicates whether patients for the table is loaded or not yet.
 * @param {String} patientsLabel - Label that is used for patients in the current org.
 * @param {number} patientsCount - The number of patients in the table.
 */
function PatientsTableFooter({ isLoading, patientsLabel, patientsCount }) {
  return (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `${patientsLabel} Count: ${patientsCount}` : ''}
    </span>
  )
}

export default PatientsTableFooter
