import React from 'react'
import RequirementCheckItem from '@components/display/RequirementCheckItem'

function RequirementsTooltip({ password, passwordConfirmation }) {
  const lengthRequirement = password?.length >= 10
  const includeUppercase = /[A-Z]/.test(password)
  const includeLowercase = /[a-z]/.test(password)
  const includeNumber = /[0-9]/.test(password)
  const includeSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)
  const passwordMatch = password === passwordConfirmation && password?.length > 0

  return (
    <div>
      <ul className="text-xs">
        <RequirementCheckItem requirement="At least 10 characters" isMet={lengthRequirement} />
        <RequirementCheckItem requirement="At least 1 uppercase letter" isMet={includeUppercase} />
        <RequirementCheckItem requirement="At least 1 lowercase letter" isMet={includeLowercase} />
        <RequirementCheckItem requirement="At least 1 number" isMet={includeNumber} />
        <RequirementCheckItem requirement="At least 1 special character" isMet={includeSpecial} />
        <RequirementCheckItem requirement="Passwords match" isMet={passwordMatch} />
      </ul>
    </div>
  )
}

export default RequirementsTooltip
