import React, { useRef, useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { Toast } from 'primereact/toast'
import { TabPanel, TabView } from 'primereact/tabview'
import MenuTab from '@components/display/MenuTab'
import PendingUsers from '../SSODirectoryUsers/PendingUsers'
import { useSSOPendingUsers } from '../SSODirectoryUsers/ssoPendingUsersHooks'
import { useAdmins } from './adminsHooks'
import ActiveAdmins from './ActiveAdmins'
import DeactivatedAdmins from './DeactivatedAdmins'

const activePanelHeaderTemplate = (options) => (
  <MenuTab
    label="Active"
    active={options.selected}
    onClick={options.onClick}
  />
)

const deactivatedPanelHeaderTemplate = (options) => (
  <MenuTab
    label="Deactivated"
    active={options.selected}
    onClick={options.onClick}
  />
)

const pendingOnboardPanelHeaderTemplate = (options, pendingAdmins, canManageSsoForOrg) => (
  canManageSsoForOrg && (
    <MenuTab
      label="Pending SSO"
      active={options.selected}
      onClick={options.onClick}
      badge={pendingAdmins?.length > 0 ? pendingAdmins.length : null}
      badgeClassName="p-badge-warning"
      icon="pi pi-users"
    />
  )
)

function Title({
  organization, searchTerm, setSearchTerm, isLoading,
}) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.adminLabelPlural}
      </span>
      <SearchField
        isLoading={isLoading}
        placeholder={`Search ${organization.adminLabelPlural} by name`}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  )
}

function AdminsTable({ useAdminHeader, canManageSsoForOrg }) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()
  const rootPath = '/admin/admins'
  const { setHeader, maxDataViewHeight } = useAdminHeader()
  const {
    data: pendingAdmins,
    isLoading: isLoadingPendingAdmins,
  } = useSSOPendingUsers({
    organizationId: organization.id,
    role: 'site_admin',
    enabled: canManageSsoForOrg,
  })
  const { data: existingAdmins } = useAdmins(organization.id, statusMessage)

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const title = {
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const primaryAction = (
    <Link to={`${rootPath}/create`}>
      <Button className="p-button-sm" label={`Create ${organization.adminLabelSingular}`} />
    </Link>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  const footer = (adminsCount, areAdminsLoading) => (
    <span className="text-sm ml-1 my-0 py-0">
      {!areAdminsLoading ? `${organization.adminLabelPlural} Count: ${adminsCount}` : '' }
    </span>
  )

  return (
    <div>
      <Toast ref={statusMessage} />
      <Card className="h-full">
        <TabView panelContainerClassName="py-2 px-0">
          <TabPanel header="Active" headerTemplate={activePanelHeaderTemplate}>
            <ActiveAdmins
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              maxDataViewHeight={maxDataViewHeight}
              rootPath={rootPath}
              footer={footer}
            />
          </TabPanel>
          <TabPanel header="Deactivated" headerTemplate={deactivatedPanelHeaderTemplate}>
            <DeactivatedAdmins
              statusMessage={statusMessage}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
              maxDataViewHeight={maxDataViewHeight}
              rootPath={rootPath}
              footer={footer}
            />
          </TabPanel>
          <TabPanel header="Pending SSO Onboard" headerTemplate={(options) => pendingOnboardPanelHeaderTemplate(options, pendingAdmins, canManageSsoForOrg)}>
            <PendingUsers
              pendingUsers={pendingAdmins}
              existingUsers={existingAdmins}
              organization={organization}
              isLoading={isLoadingPendingAdmins}
              statusMessage={statusMessage}
              searchTerm={searchTerm}
              setSearchLoading={setSearchLoading}
            />
          </TabPanel>
        </TabView>
      </Card>
    </div>
  )
}

export default AdminsTable
