import React from 'react'
import { get, map } from 'lodash'
import moment from 'moment'
import CardWithHeader from '../../components/display/CardWithHeader'
import ActivityFeed from '../../components/medManagement/ActivityFeed'
import { dateTimeTemplateFromMoment } from '../../services/utils'

function HighRiskImpacts({ organization, highRiskImpacts, maxDataViewHeight }) {
  const impactAttribute = (impact) => {
    const details = []
    const completed = get(impact, 'completedAt') != null
    const completedAt = moment(get(impact, 'completedAt'))
    const expectedAt = moment(get(impact, 'expectedAt'))
    const impactTime = completed ? completedAt : expectedAt
    const timeSinceImpact = impactTime.fromNow()
    const { effective, feedback } = impact
    if (feedback || effective !== null) {
      switch (effective) {
        case true:
          details.push('Impact: Effective')
          break
        case false:
          details.push('Impact: Ineffective')
          break
        default:
          details.push('No effectiveness data')
      }
      details.push(`Feedback: ${feedback || 'No data'}`)
    } else {
      details.push('Not Answered')
    }
    details.push(`${dateTimeTemplateFromMoment(impactTime)}`)
    return {
      title: `Occurred ${timeSinceImpact}`,
      width: '25%',
      details,
    }
  }

  const patientAttribute = (impact) => ({
    title: get(organization, 'patientLabelSingular'),
    width: '25%',
    details: [
      `${get(impact, 'patient.firstName')} ${get(impact, 'patient.lastName')}`,
      `Site: ${get(impact, 'patient.site.name')}`,
    ],
  })

  const caregiversAttribute = (impact) => {
    const completedBy = get(impact, 'completedBy')
    const completedAt = get(impact, 'completedAt') ? moment(get(impact, 'completedAt')) : null
    return ({
      title: 'Answered By',
      width: '20%',
      details: [
        completedBy && `${get(impact, 'completedBy.firstName')} ${get(impact, 'completedBy.lastName')}`,
        completedAt && `At: ${dateTimeTemplateFromMoment(completedAt)}`,
      ],
    })
  }

  const doseAttribute = (impact) => {
    const consumption = get(impact, 'consumption')
    const dose = get(consumption, 'dose')
    const medicine = get(dose, 'medicine')
    const administeredAt = moment(get(consumption, 'confirmedAt'))
    const administeredBy = get(consumption, 'confirmedBy')
    return ({
      title: `${get(medicine, 'name')} ${get(medicine, 'strength')}`,
      width: '30%',
      details: [
        `Administered: ${dateTimeTemplateFromMoment(administeredAt)}`,
        administeredBy && `By: ${get(administeredBy, 'firstName')} ${get(administeredBy, 'lastName')}`,
      ],
    })
  }

  const feedItems = map(highRiskImpacts.data, (impact) => ({
    attributes: [
      impactAttribute(impact),
      doseAttribute(impact),
      patientAttribute(impact),
      caregiversAttribute(impact),
    ],
    key: impact.id,
  }))

  return (
    <CardWithHeader title="High Risk Impacts">
      <ActivityFeed
        loading={highRiskImpacts.isLoading}
        maxDataViewHeight={maxDataViewHeight}
        items={feedItems}
      />
    </CardWithHeader>
  )
}

export default HighRiskImpacts
